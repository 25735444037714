function showCollapse(this: HTMLElement) {
    const container = this.closest('.js-collapse-tenders');
    const textBtn = this.querySelector<HTMLElement>('.js-collapse-text');
    const showText = textBtn?.dataset.showText;
    const hideText = textBtn?.dataset.hideText;


    if (container) {
        container.classList.toggle('is-show');

        if (container.classList.contains('is-show')) {
            if (hideText) textBtn.textContent = hideText;
        } else {
            if (showText) textBtn.textContent = showText;
        }
    }
}

function init(container: Element | Document = document) {
    container.querySelectorAll('.js-collapse-tenders-trigger').forEach(el => {
        el.addEventListener('click', showCollapse);
    });
}

function destroy(container: Element | Document = document) {
    document.querySelectorAll('.js-collapse-tenders-trigger').forEach(el => {
        el.removeEventListener('click', showCollapse);
    });
}

const _module = { init, destroy };

export default _module;
