import SlimSelect from 'slim-select';

const map = new WeakMap<HTMLElement, any>();

function getInstanceByElement(element: HTMLElement) {
    return map.get(element);
}

function init(container: HTMLElement | Document = document) {
    const selectList = container.querySelectorAll<HTMLInputElement>('.js-dropdown');
    const selectTopList = container.querySelectorAll<HTMLInputElement>('.js-dropdown-top');
    const selectsDocuments = document.querySelectorAll<HTMLSelectElement>('.js-select-documents');

    selectList.forEach((select) => {
        if (!select.classList.contains('select--initialization')) {
            const newselect = new SlimSelect({
                select,
                showSearch: false,
                placeholder: select.dataset.namePlaceholder || 'Выберите значение',
                showContent: select.classList.contains('js-dropdown-auto') ? 'auto' : 'down',
            });
            map.set(select, newselect);

            select.classList.add('select--initialization');

            select.addEventListener('change', () => {
                if (select.classList.contains('is-error')) {
                    select.classList.remove('is-error');
                }
            });

            select.addEventListener('update', () => {
                newselect.disable();
                newselect.enable();
            });

            if (select.classList.contains('js-dropdown-links')) {
                select.addEventListener('change', () => {
                    window.location.href = select.value;
                });
            }
        }
    });

    selectsDocuments.forEach((select) => {
        const optionsSelect = [
            {
                innerHTML: `<option>Выберите бланк</option>`,
                text: 'Выберите бланк',
                value: '',
            },
        ];

        for (let i = 1; i < select.options.length; i++) {
            const option = select.options[i];
            const documentIcon = option.dataset.document;
            const documentLink = option.dataset.link;

            optionsSelect.push({
                innerHTML: `
                    <span data-link="${documentLink}" class="faq-doc">
                        ${documentIcon ? '<span class="faq-doc__icon">${documentIcon}</span>' : ''}
                        <span class="faq-doc__text">${option.innerHTML}</span>
                    </span>`,
                text: option.innerHTML,
                value: option.value,
            });
        }

        if (!select.classList.contains('select--initialization')) {
            const newselect = new SlimSelect({
                select,
                showSearch: false,
                valuesUseText: false,
                placeholder: select.dataset.namePlaceholder || 'Выберите значение',
                data: optionsSelect,
            });

            map.set(select, newselect);

            select.classList.add('select--initialization');

            select.addEventListener('change', () => {
                if (select.classList.contains('is-error')) {
                    select.classList.remove('is-error');
                }
            });

            select.addEventListener('update', () => {
                newselect.disable();
                newselect.enable();
            });

            select.addEventListener('change', () => {
                const activeOption = select.options[select.selectedIndex];
                const link = activeOption?.querySelector<HTMLElement>(`span[data-link]`)?.dataset.link;
                if (link) {
                    const linkEl = document.createElement('a');
                    linkEl.setAttribute('href', link);
                    linkEl.setAttribute('download', '');
                    linkEl.style.display = 'none';
                    document.body.appendChild(linkEl);
                    linkEl.click();
                    document.body.removeChild(linkEl);
                }
            });
        }
    });

    selectTopList.forEach((select) => {
        if (!select.classList.contains('select--initialization')) {
            const newselect = new SlimSelect({
                select,
                showSearch: false,
                placeholder: select.dataset.namePlaceholder || 'Выберите значение',
                showContent: 'top',
            });
            map.set(select, newselect);

            select.classList.add('select--initialization');

            select.addEventListener('change', () => {
                if (select.classList.contains('is-error')) {
                    select.classList.remove('is-error');
                }
            });

            select.addEventListener('update', () => {
                newselect.disable();
                newselect.enable();
            });
        }
    });

    const selectSearchList = container.querySelectorAll<HTMLElement>('.js-search-dropdown');

    selectSearchList.forEach((select) => {
        if (!select.classList.contains('select--initialization')) {
            const newselect = new SlimSelect({
                select,
                placeholder: select.dataset.namePlaceholder || 'Выберите значение',
                showContent: 'down',
                searchPlaceholder: 'Поиск',
                searchText: 'Ничего не найдено',
            });
            map.set(select, newselect);

            select.classList.add('select--initialization');

            select.addEventListener('update', () => {
                newselect.disable();
                newselect.enable();
            });
        }
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-dropdown')).forEach((el) => {
        const select = map.get(el);

        if (select) {
            select.destroy();
            map.delete(el);
        }
    });

    Array.from(container.querySelectorAll<HTMLElement>('.js-select-documents')).forEach((el) => {
        const select = map.get(el);

        if (select) {
            select.destroy();
            map.delete(el);
        }
    });

    Array.from(container.querySelectorAll<HTMLElement>('.js-dropdown-top')).forEach((el) => {
        const select = map.get(el);

        if (select) {
            select.destroy();
            map.delete(el);
        }
    });

    Array.from(container.querySelectorAll<HTMLElement>('.js-search-dropdown')).forEach((el) => {
        const select = map.get(el);

        if (select) {
            select.destroy();
            map.delete(el);
        }
    });
}

const _module = { init, destroy, getInstanceByElement };

export default _module;
