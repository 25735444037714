// import flatpickr from "flatpickr/dist/flatpickr.js";
// const Russian = require("flatpickr/dist/l10n/ru.js").default.ru;
// import datepicker from 'js-datepicker'
// import 'litepicker-polyfills-ie11';
// then import Litepicker
import Litepicker from 'litepicker';
import 'litepicker/dist/plugins/multiselect';
import 'litepicker/dist/plugins/ranges';

const SELECTOR = '.js-flatpickr';

const map = new WeakMap();

async function init(container: Element | Document = document) {

    const elements = Array.from(container.querySelectorAll(SELECTOR));

    if (elements.length > 0) {

        elements.forEach((el) => {
            const clearTrigger = el.closest('.input-block-calendar')?.querySelector('[data-clear]');

            const datepicker = new Litepicker({
                element: el,
                numberOfColumns: 2,
                numberOfMonths: 2,
                lang: 'ru',
                format: 'DD.MM.YYYY',
                singleMode: false,
                showTooltip: false
            });

            datepicker.on('hide', () => {
                el.dispatchEvent(new Event('change'));

            })

            map.set(el, datepicker);

            el.addEventListener('update', () => {
                datepicker.clearSelection();
            });

            clearTrigger?.addEventListener('click', () => {
                datepicker.clearSelection();
            })
        });

    }



    // const elements = Array.from(container.querySelectorAll(SELECTOR));

    // if (elements.length > 0) {

    //     elements.forEach((el) => {
    //         const clearTrigger = el.closest('.input-block-calendar')?.querySelector('[data-clear]');

    //         const datepickerEl = datepicker(el, {
    //             // locale: Russian,
    //             // mode: "range",
    //             // dateFormat: "Y-m-d",
    //             // altInput: true,
    //             // altFormat: "d.m.Y",
    //         });

    //         map.set(el, datepickerEl);

    //         el.addEventListener('update', () => {
    //             datepickerEl.clear();
    //         });

    //         clearTrigger?.addEventListener('click', () => {
    //             datepickerEl.clear();
    //         })
    //     });

    // }
}

function destroy(container: Element | Document = document) {
    // const elements = Array.from(container.querySelectorAll(SELECTOR));

    // elements.forEach((el) => {
    //     const datepicker = map.get(el);

    //     if (datepicker) {
    //         datepicker.destroy();
    //         map.delete(el);
    //     }
    // });
}

function getInstanceByElement(el: Element) {
    return map.get(el);
}

const module = { init, destroy, getInstanceByElement };

export default module;
