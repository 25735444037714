import createFormSender from '../../modules/ajax-form-sender';
import createValidator from '../../modules/validator';
import inputsOfForm from '../components/inputs';

const SELECTOR = '.js-ajax-form';
const map = new WeakMap<HTMLFormElement, Record<string, any>>();

const clearAntispamInput = (form: HTMLFormElement) => {
    const checkInput = form.querySelector<HTMLInputElement>('input[name="check_val"]');
    if (checkInput) {
        checkInput.value = '';
    }
};

export default function ajaxForm() {
    inputsOfForm();
    const forms = Array.from(document.querySelectorAll<HTMLFormElement>(SELECTOR));

    if (forms.length > 0) {
        forms.forEach((form) => {
            let isSubmitting = false;

            const formMessageElement = form.querySelector('.js-form-message');

            const validator = createValidator(form, {
                scrollToInvalidInputOptions: {
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center',
                },
            });
            const sender = createFormSender(form, {
                shouldClearInputs: true,
                onBeforeSend: () => {
                    clearAntispamInput(form);
                },
                onSuccess: ({ success, message }) => {
                    if (formMessageElement && message) {
                        formMessageElement.innerHTML = message;
                    }
                    if (form.classList.contains('scroll-top')) {
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        });
                    }

                    if (success && form.classList.contains('js-update-captcha-form')) {
                        form.dispatchEvent(new CustomEvent('update-captcha'));
                    }

                },
            });
            map.set(form, sender);

            form.addEventListener('submit', (event) => {
                if (isSubmitting) return;
                event.preventDefault();
                const isFormValid = validator.validate();
                if (isFormValid) {
                    isSubmitting = true;
                    sender.send().finally(() => {
                        isSubmitting = false;
                    });
                }
            });
        });
    }
}
