export const fetchSharing = () =>
    import(
        './components/sharing'
        /* webpackChunkName: "sharing" */
    ).then((m) => m.default);

export const fetchScrollToPlugin = () =>
    import(
        'gsap/ScrollToPlugin'
        /* webpackChunkName: "gsap-scrollto-plugin" */
    );
export const fetchIMask = async () => import('imask' /* webpackChunkName: "imask" */).then((m) => m.default);
