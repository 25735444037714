import Swiper, { Navigation } from 'swiper';

Swiper.use([Navigation]);

const map = new WeakMap<HTMLElement, any>();

function init(container: HTMLElement | Document = document) {
    const gallerySliders = Array.from(container.querySelectorAll<HTMLElement>('.js-editor-gallery-slider'));

    gallerySliders.forEach(slider => {
        let swiper = new Swiper(slider, {
            slidesPerView: 1,
            spaceBetween: 10,
            loop: true,
            navigation: {
                nextEl: '.js-gallery-btn-next',
                prevEl: '.js-gallery-btn-prev',
            },
            breakpoints: {
                460: {
                    slidesPerView: 1.5,
                },
                767: {
                    spaceBetween: 24,
                },
                1023: {
                    slidesPerView: 2.5,
                    spaceBetween: 32
                },
                1440: {
                    slidesPerView: 3.07,
                    spaceBetween: 48
                }
            }
        });
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-editor-gallery-slider')).forEach((el) => {
        const slider = map.get(el);

        if (slider) {
            slider.destroy();
            map.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;
