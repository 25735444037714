function validateCheckbox(this: HTMLElement) {
    const popup: HTMLElement | null = this.closest('.js-invitation-popup');
    const checkbox = popup?.querySelector<HTMLInputElement>('.js-invitation-checkbox');
    if (checkbox && checkbox.checked) addToLocalStorage();
}

function addToLocalStorage() {
    localStorage.setItem('invitationChecked', 'true');
}

function closePopup(this: HTMLElement) {
    const popup: HTMLElement | null = this.closest('.js-invitation-popup');
    popup?.classList.remove('is-open');
    document.body.style.overflow = '';
    addToLocalStorage();
}

function init(container: HTMLElement | Document = document) {
    const popup = container.querySelector<HTMLElement>('.js-invitation-popup');
    const closePopupBtn = popup?.querySelectorAll<HTMLElement>('.js-close-invitation-popup');
    const linkPopup = popup?.querySelector<HTMLElement>('.js-invitation-link');

    if (popup && window.location.pathname=='/' && localStorage.getItem('invitationChecked') == null){
        setTimeout(() => {
            popup.classList.add('is-open');
            document.body.style.overflow = 'hidden';
        }, 45000);
    }

    if (closePopupBtn) {
        closePopupBtn.forEach(btn => {
            btn.addEventListener('click', validateCheckbox);
        })
    }

    if (linkPopup) {
        linkPopup.addEventListener('click', closePopup);
    }
}

function destroy(container: HTMLElement | Document = document) {
    const closePopupBtn = container.querySelectorAll<HTMLElement>('.js-close-invitation-popup');
    const linkPopup = container.querySelector<HTMLElement>('.js-invitation-link');

    closePopupBtn.forEach(btn => {
        btn.removeEventListener('click', validateCheckbox);
    })

    linkPopup?.removeEventListener('click', validateCheckbox);
}

const _module = { init, destroy };

export default _module;
