function prettify(num: number) {
    const n = num.toString();
    return n.replace(/(\d)(?=(\d{3})+(\D|$))/g, "$1 ");
}

function calcZola(el: HTMLElement) {
    const container = el.closest('.js-zola-calculator');
    // инпуты с ввведенными значениями
    const inputCementValue =
        container?.querySelector<HTMLInputElement>('.js-zola-calculator-cement')?.value.replace(/ /g,'');
    const inputZolaValue =
        container?.querySelector<HTMLInputElement>('.js-zola-calculator-zola')?.value.replace(/ /g,'');
    const inputReplacementValue =
        container?.querySelector<HTMLInputElement>('.js-zola-calculator-replacement')?.value.replace(/ /g,'');


    // значения в тексте
    const finalPriceInner = container?.querySelector('.js-zola-calculator-price');
    const finalEconomyInner = container?.querySelector('.js-zola-calculator-economy');
    const replacementValueInner = container?.querySelector('.js-zola-calculator-replacement-value');

    // значения на диаграмме
    const finalPriceDiagram = container?.querySelector('.js-zola-calculator--diagram-price');
    const finaZolaPercentInner = container?.querySelector('.js-zola-calculator-zola-percent');
    const finalCementPercentInner = container?.querySelector('.js-zola-calculator-cement-percent');
    const finalEconomyPercentInner = container?.querySelector('.js-zola-calculator-ecomomy-percent');
    const diagramCircle = container?.querySelector<SVGElement>('.js-zola-calculator-diagram');

    if (inputCementValue && inputReplacementValue && inputZolaValue) {
        // считаем итоговую стоимость
        const finalPrice = Math.round((
            parseFloat(inputCementValue) *
            (100 - parseFloat(inputReplacementValue)) +
            parseFloat(inputZolaValue) *
            parseFloat(inputReplacementValue)
        ) / 100);

        // заполняем значения в тексте
        if (finalPriceInner) finalPriceInner.textContent = `${prettify(finalPrice)}`;
        if (replacementValueInner) replacementValueInner.textContent = `
            ${inputReplacementValue}/${100 - parseFloat(inputReplacementValue)}
        `;
        if (finalEconomyInner) finalEconomyInner.textContent = `${prettify(parseFloat(inputCementValue) - finalPrice)}`;

        // заполняем значения в диаграмме
        if (finalPriceDiagram) finalPriceDiagram.textContent = `${finalPrice}`;
        if (finaZolaPercentInner) finaZolaPercentInner.textContent = inputReplacementValue;
        if (finalCementPercentInner) finalCementPercentInner.textContent = `${100 - parseFloat(inputReplacementValue)}`;
        const percentEconomy = Math.round(100 - (finalPrice / parseFloat(inputCementValue) * 100));
        if (finalEconomyPercentInner) finalEconomyPercentInner.textContent = `${percentEconomy}`;
        if (diagramCircle) {
            diagramCircle.style.strokeDasharray = `${1037 * parseFloat(inputReplacementValue) / 100} 1037`;
        }
    }
}

function onClickCalc(this: HTMLElement) {
    calcZola(this);
}

function init(container: HTMLElement | Document = document) {
    const arrCalcTrigger = container.querySelectorAll<HTMLElement>('.js-zola-calculator-calc');
    arrCalcTrigger.forEach(btn => {
        calcZola(btn);
        btn.addEventListener('click', onClickCalc);
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-zola-calculator-calc')).forEach((el) => {
        el.removeEventListener('click', onClickCalc);
    });
}

const _module = { init, destroy };

export default _module;
