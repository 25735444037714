/* global BUILD_TYPE */
/* eslint global-require: "off" */
// import 'css-paint-polyfill';
import 'focus-visible';

if (BUILD_TYPE === 'legacy') {
    require('core-js/es/symbol');
    require('core-js/es/promise/finally');
    require('document-register-element/build/document-register-element');
    require('abortcontroller-polyfill/dist/polyfill-patch-fetch');
    require('md-gum-polyfill');
    require('resize-observer-polyfill');
    var Promise = require('promise-polyfill').default;
    require('whatwg-fetch');
    require('objectFitPolyfill');
    require('core-js/es/array/includes');
    require('core-js/es/array/find-index');
    require('core-js/es/object/assign');

    Number.isNaN = Number.isNaN || function(value) {
        return typeof value === 'number' && isNaN(value);
    }
}
