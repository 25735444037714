import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

let asideNav;

const header = document.querySelector('.js-header');
const headerHeight = header.offsetHeight;

function scrollToElement(event) {
    if (event) {
        event.preventDefault();
    }

    const id = this.dataset.scrolltoId;
    if (!id) {
        console.error('[scrollto] No id provided.');
        return;
    }
    const targetElement = document.getElementById(id);
    if (targetElement) {
        // const scrollTarget = targetElement.getBoundingClientRect().top - document.body.getBoundingClientRect().top - headerHeight - 30;
        const scrollTarget = targetElement.getBoundingClientRect().top + window.scrollY - headerHeight + 4;
        gsap.to(window, {
            duration: 0.3,
            scrollTo: scrollTarget,
            ease: 'power4.easeOut',
            onComplete: () => {
                if (targetElement.dataset.navTheme && targetElement.dataset.navTheme === 'light') {
                    asideNav?.classList.add('light-theme')
                }

                if (targetElement.classList.contains('js-fly-ash-request')) {
                    targetElement.click();
                }

                if (targetElement.classList.contains('js-collpased-el')) {
                    const trggerCollapse =
                        targetElement.classList.contains('js-collapse-trigger') ?
                            targetElement :
                            targetElement.querySelector('.js-collapse-trigger');

                    if (trggerCollapse.getAttribute('aria-expanded') === 'false') {
                        trggerCollapse.click();
                    }
                }
            }
        });
    }
}

function init(container = document) {
    asideNav = container.querySelector('.js-aside-nav');
    const anchorLinks = Array.from(container.querySelectorAll('[data-scrollto-id]'));
    if (anchorLinks.length) {
        anchorLinks.forEach((link) => link.addEventListener('click', scrollToElement));
    }
}

function destroy(container = document) {
    asideNav = null;
    const anchorLinks = Array.from(container.querySelectorAll('[data-scrollto-id]'));
    if (anchorLinks.length) {
        anchorLinks.forEach((link) => link.removeEventListener('click', scrollToElement));
    }
}

const _module = { init, destroy };

export default _module;
