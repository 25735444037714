import delegate from 'delegate';
import { BaseResponse } from '../types';
import {getRequest} from './getRequestFilters';

type DocsResponse = BaseResponse & {
    data: {
        listHtml: string;
    };
};

let delegation: any;

async function fetchData(event: any) {
    const target = event.delegateTarget as HTMLInputElement;
    const content = target.closest<HTMLElement>('.js-content-block');
    const container = content ? content.querySelector<HTMLElement>('.js-content') : null;
    const url = document.location.href;
    const nameCount = target.name;
    const count = {[nameCount]: target.value};

    if (container) {
        getRequest(container, url, count, false);
    }
}

function init(container: Element | Document = document) {
    delegation = delegate(container, '.js-count-trigger', 'change', fetchData);
}

function destroy(container: Element | Document = document) {

    if (delegation) {
        delegation.destroy();
        delegation = null;
    }
}

const _module = { init, destroy };

export default _module;
