// import barba from '@barba/core';
import reveal from '../inits/reveal';
import popups from '../inits/lit-popup.js';
import outerPopups from '../inits/outer-popups.js';
import masks from '../inits/masks';
import ajaxForm from '../inits/ajax-form';
import createFileUpload from '../components/file-upload';
// import { createMainSearchbar } from '../components/main-searchbar';
import sharing from '../inits/sharing';

let masksComponent: any;

const wysiwygEl = document.querySelector<HTMLElement>('.editor:not(.editor-ecology)');

if (wysiwygEl) {
    Array.from(wysiwygEl.children).forEach((el) => {
        el.style.opacity = 0;
        el.style.transform = 'none';
        setTimeout(() => {
            el.style.opacity = '';
            el.style.transform = '';
            el.classList.add('js-reveal', 'a-reveal');
        }, 1);
    });
    setTimeout(() => {
        reveal.init(wysiwygEl);
    }, 50);
}
// пока нет барбы
popups.init();
outerPopups.init();
masksComponent = masks();
ajaxForm();
// const mainSearchForm = document.querySelector('form.js-main-searchbar') as HTMLFormElement | null;
// if (mainSearchForm) {
//     createMainSearchbar(mainSearchForm);
// }
popups.init();
reveal.init();

Array.from(document.querySelectorAll('.js-file-upload')).forEach((el) => createFileUpload(el));
sharing.init();

// когда будет бесшовность

// barba.hooks.once((next) => {
//     popups.init(next.container);
// })

// barba.hooks.beforeEnter(() => {
//     outerPopups.init();
//     masksComponent = masks();
//     ajaxForm();
//     const mainSearchForm = document.querySelector('form.js-main-searchbar') as HTMLFormElement | null;
//     if (mainSearchForm) {
//         createMainSearchbar(mainSearchForm);
//     }
// Array.from(document.querySelectorAll('.js-file-upload')).forEach((el) => createFileUpload(el));
// sharing.init();
// });

// barba.hooks.after((next) => {
//     popups.init(next.container);
// })

// barba.hooks.afterEnter(({next}) => {
//     reveal.init(next.container);
// });

// barba.hooks.afterLeave(({current}) => {
//     reveal.destroy(current.container);
// });

// barba.hooks.beforeLeave((data) => {
//     if (masksComponent) {
//         masksComponent.destroy();
//         masksComponent = null;
//     }
// });

// barba.hooks.afterLeave((current) => {
//     popups.destroy(current.container);
//     outerPopups.destroy();
// sharing.destroy();
// });
