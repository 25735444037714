function toggleList(this: HTMLElement) {
    const target = this;
    target.blur();
    const container = target.closest<HTMLElement>('.js-show-more-container');
    const inner = container?.querySelector<HTMLElement>('.js-show-more-inner');

    const height = inner?.offsetHeight;

    if (container) {
        target.classList.toggle('is-active');
        container?.classList.toggle('is-show');

        if (container.classList.contains('is-show')) {
            container.style.height = `${height}px`;
        } else {
            container.style.height = '100%';
        }
    }
}

function createButton(el: HTMLElement | null) {
    const container:HTMLElement|null|undefined = el?.querySelector<HTMLElement>('.js-show-more-container');
    const buttonWrapper = document.createElement('div');
    buttonWrapper.classList.add('js-show-more-button-wrapper');
    buttonWrapper.classList.add('show-more-button-wrapper');
    container?.append(buttonWrapper);
    buttonWrapper.innerHTML = `
            <div class="task-block__bottom">
                <button class="button button-icon task-block__more js-show-more">
                    <span class="button__round task-block__btn-icon">
                        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.3334 1.3335L6.00008 6.00016L1.66675 1.3335" stroke="#1F1F22" stroke-width="1.5" stroke-linecap="square"></path>
                        </svg>
                    </span>
                    <span class="button__text button__text--show"><span>Еще</span></span>
                    <span class="button__text button__text--hide"><span>Закрыть</span></span>
                </button>
            </div>
    `;
}

function checkHeights(el: HTMLElement | null) {
    if (el instanceof Event) {
        const collapseEls = Array.from(document.querySelectorAll('.js-show-more-wrapper'));
        collapseEls.forEach(elem => {
            const container:HTMLElement|null|undefined = elem?.querySelector('.js-show-more-container');
            const inner:HTMLElement|null|undefined = elem?.querySelector('.js-show-more-inner');
            const buttonWrapper:HTMLElement|null|undefined = elem?.querySelector('.js-show-more-button-wrapper');
            const containerHeight = container?.offsetHeight;
            const innerHeight = inner?.offsetHeight;

            if (containerHeight && innerHeight && containerHeight < innerHeight) {
                buttonWrapper?.classList.add('is-show');
            } else {
                if (!container?.classList.contains('is-show')) {
                    buttonWrapper?.classList.remove('is-show');
                }
            }
        });
    } else {
        const container:HTMLElement|null|undefined = el?.querySelector('.js-show-more-container');
        const inner:HTMLElement|null|undefined = el?.querySelector('.js-show-more-inner');
        const buttonWrapper:HTMLElement|null|undefined = el?.querySelector('.js-show-more-button-wrapper');
        const containerHeight = container?.offsetHeight;
        const innerHeight = inner?.offsetHeight;

        if (containerHeight && innerHeight && containerHeight < innerHeight) {
            buttonWrapper?.classList.add('is-show');
        } else {
            if (!container?.classList.contains('is-show')) {
                buttonWrapper?.classList.remove('is-show');
            }
        }
    }
}

function init(container: Element | Document = document) {
    const collapseEls = Array.from(container.querySelectorAll('.js-show-more-wrapper'));

    collapseEls.forEach(el => {
        createButton(el);
        checkHeights(el);
    })

    window.addEventListener('resize', () => {
        document.querySelectorAll('.js-show-more-wrapper').forEach(el => {
            checkHeights(el);
        });
    });

    container.querySelectorAll('.js-show-more').forEach(el => {
        el.addEventListener('click', toggleList);
    });
}

function destroy(container: Element | Document = document) {
    document.querySelectorAll('.js-show-more').forEach(el => {
        el.removeEventListener('click', toggleList);
    });

    window.removeEventListener('resize', () => {
        document.querySelectorAll('.js-show-more-wrapper').forEach(el => {
            checkHeights(el);
        });
    });
}

const _module = { init, destroy };

export default _module;
