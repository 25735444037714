import gsap from 'gsap';

export function contentAnimation() {
    const tl = gsap.timeline();
    const secondContainers = document.querySelectorAll('.a-second-container');
    tl.to('.a-preloader', {
        duration: 0.5,
        autoAlpha: 0,
    })
        .from('.a-header', {
            y: -30,
            duration: 0.8,
            opacity: 0,
        })
        .from(
            '.a-announce',
            {
                y: -30,
                duration: 0.8,
                opacity: 0,
            },
            1.3,
        )
        .from(
            secondContainers,
            {
                y: 30,
                duration: 0.8,
                opacity: 0,
            },
            1.3,
        );

    tl.play();
}
