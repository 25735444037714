import Stickyfill from 'stickyfilljs';

function init() {
    const stickyEls = document.querySelectorAll<HTMLElement>('.sticky');
    if (stickyEls && stickyEls.length > 0) {
        Stickyfill.add(stickyEls);
    }
}

export default { init };
