import delegate from 'delegate';
import axios from 'axios';
import collapse from '../custom-elements/Collapse/Collapse';
import scrollToElement from './scroll-to-element';
import serialize from 'form-serialize';

let delegation: any;

async function getRequest(container: HTMLElement | null, url: string, dataForm: any = '', target: HTMLElement) {
    target?.classList.add('is-loading');

    const response = await axios.get(url, {
        params: dataForm,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
    })
        .then(response => response.data)
        .then(data => {
            if (container && data.data.listHtml) container.innerHTML = data.data.listHtml;
        })
        .catch((err) => {
            if (err.name !== 'AbortError') {
                throw err;
            }
        })
        .finally(() => {
            setTimeout(() => {
                target?.classList.remove('is-loading');
            }, 300);

            collapse.setCollapse(target, true);

            const contactsInner = container?.querySelector<HTMLElement>('.contacts__inner');
            if (contactsInner) {
                contactsInner.style.transform = 'translate3d(0, 30px, 0)';
                contactsInner.style.opacity = '0';
                contactsInner.style.visibility = 'hidden';

                setTimeout(() => {
                    contactsInner.style.transform = 'translate3d(0, 0, 0)';
                    contactsInner.style.opacity = '1';
                    contactsInner.style.visibility = 'visible';
                }, 300);
            }

        });
}

async function fetchData(this: HTMLInputElement, event: any) {
    const target = event.delegateTarget as HTMLAnchorElement;
    const activeCollapse = target.getAttribute('aria-expanded');
    if (activeCollapse == 'true') {
        collapse.setCollapse(target, true);
        return;
    }

    const filtersContainer = target.closest<HTMLElement>('.js-content-block');
    const formFilters: HTMLFormElement | null = filtersContainer ? filtersContainer.querySelector('.js-filters-form') : null;
    const content = target.closest<HTMLElement>('.js-contacts-content-block');
    const container = content ? content.querySelector<HTMLElement>('.js-contacts-content') : null;
    const { sectionId, urlRequest } = target.dataset;

    let dataForm;

    if (formFilters && sectionId) {
        dataForm = serialize(formFilters, { hash: true });
        dataForm.section_id = sectionId;
    } else if (!formFilters && sectionId) {
        dataForm = {
            'section_id': sectionId
        }
    }

    if (urlRequest && container) {
        getRequest(container, urlRequest, dataForm, target);
    }
}

async function fetchDataGroup(this: HTMLInputElement, event: any) {
    const target = event.delegateTarget as HTMLAnchorElement;
    const content = target.closest<HTMLElement>('.js-contacts-request-group-container');
    const activeCollapse = target.getAttribute('aria-expanded');

    if (activeCollapse === 'true') {
        target.setAttribute('aria-expanded', 'false');
        const groupCollpses = content?.querySelectorAll('.js-collapse-trigger');
        const groupCollpsesTriggers = content?.querySelectorAll('.js-contacts-request-group');
        groupCollpses?.forEach(collapseTrigger => {
            collapseTrigger.setAttribute('aria-expanded', 'false');
            collapse.closeCollapse(collapseTrigger);
        })
        groupCollpsesTriggers?.forEach(collapseTrigger => {
            collapseTrigger.setAttribute('aria-expanded', 'false');
            // collapse.closeCollapse(collapseTrigger);
        })

        return;
    }

    const filtersContainer = target.closest<HTMLElement>('.js-content-block');
    const formFilters: HTMLFormElement | null = filtersContainer ? filtersContainer.querySelector('.js-filters-form') : null;
    const container = content ? content.querySelector<HTMLElement>('.js-contacts-content') : null;
    const { sectionId, urlRequest } = target.dataset;

    let dataForm;

    if (formFilters && sectionId) {
        dataForm = serialize(formFilters, { hash: true });
        dataForm.section_id = sectionId;
    } else if (!formFilters && sectionId) {
        dataForm = {
            'section_id': sectionId
        }
    }

    if (urlRequest && container) {
        getRequest(container, urlRequest, dataForm, target);
    }
}

async function setParentStatus(this: HTMLInputElement, event: any) {
    const target = event.delegateTarget as HTMLAnchorElement;
    const content = target.closest<HTMLElement>('.js-contacts-request-group-container');
    const mainTarget = content?.querySelector<HTMLElement>('.js-contacts-request-group');
    const collapses = content?.querySelectorAll<HTMLElement>('.js-contacts-request-group-child');

    let countActiveCollapses = 0;
    collapses?.forEach(item => {
        if (item.getAttribute('aria-expanded') === 'true') {
            countActiveCollapses++;
        }
    })

    if (countActiveCollapses === 0) mainTarget?.setAttribute('aria-expanded', 'false');
}

function init(container: Element | Document = document) {
    delegation = delegate(container, '.js-contacts-request', 'click', fetchData);
    delegation = delegate(container, '.js-contacts-request-group', 'click', fetchDataGroup);
    delegation = delegate(container, '.js-contacts-request-group-child', 'click', setParentStatus);
}

function destroy(container: Element | Document = document) {
    if (delegation) {
        delegation.destroy();
        delegation = null;
    }
}

const _module = { init, destroy };

export default _module;
