import { fetchSharing } from '../dynamic-modules';

let destroySharing: any;

async function init(container: Element | Document = document) {
    const elements = Array.from(document.querySelectorAll('.js-share'));

    if (elements.length > 0) {
        const sharing = await fetchSharing();

        destroySharing = sharing({
            vk: elements.some((el) => el.classList.contains('share-vk')),
            fb: elements.some((el) => el.classList.contains('share-fb')),
            twitter: elements.some((el) => el.classList.contains('share-twitter')),
            telegram: elements.some((el) => el.classList.contains('share-telegram')),
        });
    }
}

function destroy(): void {
    if (destroySharing) {
        destroySharing();
    }
}

const module = { init, destroy };

export default module;
