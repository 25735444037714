import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Navigation, Pagination]);

const map = new WeakMap<HTMLElement, any>();

function init(container: HTMLElement | Document = document) {
    const arrSlider = container.querySelectorAll<HTMLElement>('.js-main-slider');

    arrSlider.forEach(slider => {
        const navigation = slider.querySelector<HTMLElement>('.js-navigation');
        if (navigation) initNavigation(navigation);
        let swiper = new Swiper(slider, {
            slidesPerView: 1,
            loop: true,
            speed: 800,
            pagination: {
                el: ".js-pagination",
                bulletClass: 'intro-slider__bullet',
                bulletActiveClass: 'is-active',
                clickable: true
            },
            navigation: {
                nextEl: '.js-btn-next',
                prevEl: '.js-btn-prev',
                disabledClass: 'is-disable',
            },
        });

        map.set(slider, swiper);
    });
}

function initNavigation(container: HTMLElement | null) {
    if (container) container.innerHTML = `
    <div class="intro-slider__ctrl">
        <button class="js-btn-prev button button__round slider-btn slider-btn--prev" aria-label="предыдущий слайд">
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.3334 1.3335L6.00008 6.00016L1.66675 1.3335" stroke="#1F1F22" stroke-width="1.5" stroke-linecap="square"/>
            </svg>
        </button>
        <button class="js-btn-next button button__round slider-btn slider-btn--next" aria-label="следующий слайд">
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.3334 1.3335L6.00008 6.00016L1.66675 1.3335" stroke="#1F1F22" stroke-width="1.5" stroke-linecap="square"/>
            </svg>
        </button>
    </div>
        `;
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-main-slider')).forEach((el) => {
        const slider = map.get(el);

        if (slider) {
            slider.destroy();
            map.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;
