import SlimSelect from 'slim-select';

let block: HTMLElement | null, copyBlock: Node | undefined, selects: NodeListOf<HTMLSelectElement> | undefined;

const map = new WeakMap();

function setCopyBlock() {
    const inner = document.querySelector<HTMLElement>('.js-copy-inner');
    // let selects;
    if (inner && block && copyBlock) {
        if (matchMedia('(max-width: 576px)').matches) {
            inner.innerHTML = '';
            inner.appendChild(copyBlock);
            block.innerHTML = '';

            selects = inner.querySelectorAll<HTMLSelectElement>('.js-dropdown-copy');
        } else {
            block.innerHTML = '';
            block.appendChild(copyBlock);
            inner.innerHTML = '';

            selects = block.querySelectorAll<HTMLSelectElement>('.js-dropdown-copy');
        }

        selects?.forEach((el) => {
            const newselect = new SlimSelect({
                select: el,
                showSearch: false,
                placeholder: el.dataset.namePlaceholder || 'Выберите значение',
                showContent: 'down',
                beforeOpen: function () {},
                onChange: function (info) {},
            });

            el.addEventListener('update', () => {
                newselect.disable();
                newselect.enable();
            });

            map.set(el, newselect);
        });
    }
}

function init(container: Element | Document = document) {
    block = container.querySelector('.js-copy-block');
    copyBlock = block?.cloneNode(true);
    setCopyBlock();
}

function destroy(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll('.js-dropdown-copy'));

    elements.forEach((el) => {
        const datepicker = map.get(el);

        if (datepicker) {
            datepicker.destroy();
            map.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;
