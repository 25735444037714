/* global $ */
import LitPopup from 'lit-popup';
import axios from 'redaxios';

let lcCheckInput: HTMLSelectElement | null;

async function checkLc(event: any) {
    event.preventDefault();

    try {
        if (lcCheckInput) {
            const { data } = await axios.post(
                '/local/ajax/lcCheck.php',
                { data: { bspid: 'KRSK', fnum: lcCheckInput.value } },
                { responseType: 'json' },
            );

            if (data.success) {
                window.location.href = data.link;
            } else {
                // $('#lcCheckError').show();
            }
        }
    } catch (err) {
        console.error(err);
        // $('#lcCheckError').show();
    }
}

function initSelectPicker() {
    // $('.selectpicker').selectpicker();
    // const caret = $('<span class="bs-caret bs-caret-custom"><span class="caret"></span></span>');
    // setTimeout(() => {
    //     $('.b-select .bs-placeholder').append(caret.clone());
    // }, 200);
}

function init() {
    // initSelectPicker();
    const element = document.querySelector('[data-lit-popup="auth"]') as HTMLElement | null;

    if (element) {
        const name = element.dataset.litPopup;

        if (name) {
            const popup = new LitPopup(name, {
                onOpen() {
                    document.body.classList.add('no-scroll');
                },
                onCloseComplete() {
                    document.body.classList.remove('no-scroll');
                },
            });
            const submitLcCheckBtn = element.querySelector('#submitLcCheck');
            lcCheckInput = element.querySelector('#lcCheckInput') as HTMLSelectElement | null;

            if (submitLcCheckBtn) {
                submitLcCheckBtn.addEventListener('click', checkLc);
            }
        }
    }
}

const _module = { init };

export default _module;
