import GLightbox from 'glightbox/dist/js/glightbox.js';
import 'glightbox/dist/css/glightbox.css';

const map = new WeakMap<HTMLElement, any>();

function init(container: HTMLElement | Document = document) {

    const lightboxArrVideo = container.querySelectorAll('.js-glightbox-video');
    lightboxArrVideo.forEach((lightboxBtn, i) => {
        lightboxBtn.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
        })
    });
    const lightboxGalleryVideo = GLightbox({
        selector: '.js-glightbox-video',
        touchNavigation: true,
    });

    const lightboxArr = container.querySelectorAll('.js-glightbox-gallery');
    lightboxArr.forEach((lightboxBtn, i) => {
        lightboxBtn.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
        })
    });
    const lightboxGallery = GLightbox({
        selector: '.js-glightbox-gallery',
        touchNavigation: true,
    });
}


function destroy(container: HTMLElement | Document = document) {

    Array.from(container.querySelectorAll<HTMLElement>('.js-glightbox-video')).forEach((el) => {
        const gallery = map.get(el);

        if (gallery) {
            gallery.destroy();
            map.delete(el);
        }
    });

    Array.from(container.querySelectorAll<HTMLElement>('.js-glightbox-gallery')).forEach((el) => {
        const gallery = map.get(el);

        if (gallery) {
            gallery.destroy();
            map.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;
