import serialize from 'form-serialize';
import delegate from 'delegate';
import {getRequest} from './getRequestFilters';

let delegation: any;

async function fetchData(this: HTMLInputElement, event: any) {
    event.preventDefault();

    const target = event.delegateTarget as HTMLAnchorElement;
    const content = target.closest<HTMLElement>('.js-content-block');
    const container = content ? content.querySelector<HTMLElement>('.js-content') : null;
    const formFilters: HTMLFormElement | null = target.closest('.js-filters-form');
    const url = formFilters?.action;

    const periodInput = content?.querySelector<HTMLInputElement>('.js-period-input');
    const periodFromInput = content?.querySelector<HTMLInputElement>('.js-period-input-from');
    const periodToInput = content?.querySelector<HTMLInputElement>('.js-period-input-to');
    const newPeriod = periodInput?.value.split(' — ');
    if (periodFromInput && newPeriod && newPeriod[0]) periodFromInput.value = newPeriod[0];
    if (periodToInput && newPeriod && newPeriod[1]) periodToInput.value = newPeriod[1];

    if (target.classList.contains('js-filter-clear')) {
        const selects = formFilters?.querySelectorAll<HTMLSelectElement>('.js-dropdown');
        const selectsCopy = formFilters?.querySelectorAll<HTMLSelectElement>('.js-dropdown-copy');
        const inputs = formFilters?.querySelectorAll<HTMLInputElement>('input:not([name="set_filter"])');
        const checkboxes = formFilters?.querySelectorAll<HTMLInputElement>('input[type="checkbox"]');
        const calendar = formFilters?.querySelectorAll<HTMLInputElement>('.js-flatpickr');

        selects?.forEach(el => {
            el.selectedIndex  = 0;
            el.dispatchEvent(new Event('update'));
        })

        selectsCopy?.forEach(el => {
            el.selectedIndex  = 0;
            el.dispatchEvent(new Event('update'));
        })

        calendar?.forEach(el => {
            el.dispatchEvent(new Event('update'));
            const calendarContainer = el.closest<HTMLElement>('.js-calendar-container');
            if (calendarContainer) calendarContainer.classList.remove('is-not-empty');
        })

        inputs?.forEach(el => {
            el.value = '';
        })

        checkboxes?.forEach(el => {
            el.checked = false;
        })

        if (url && container) {
            getRequest(container, url, '', false);
        }
    } else {
        let dataForm;

        if (formFilters) dataForm = serialize(formFilters, { hash: true });

        if (url && container) {
            getRequest(container, url, dataForm);
        }
    }
}

async function fetchDataCalendar(this: HTMLInputElement, event: any) {
    event.preventDefault();

    const target = this as HTMLInputElement;
    const content = target.closest<HTMLElement>('.js-content-block');
    const container = content ? content.querySelector<HTMLElement>('.js-content') : null;
    const formFilters: HTMLFormElement | null = target.closest('.js-filters-form');
    const url = formFilters?.action;

    const calenddarContainer = target.closest<HTMLElement>('.js-calendar-container');
    const periodInput = content?.querySelector<HTMLInputElement>('.js-period-input');
    const periodFromInput = content?.querySelector<HTMLInputElement>('.js-period-input-from');
    const periodToInput = content?.querySelector<HTMLInputElement>('.js-period-input-to');
    const newPeriod = periodInput?.value.split(' - ');
    let newPeriodFormatedFrom;
    let newPeriodFormatedTo;
    if (newPeriod) {
        newPeriodFormatedFrom = newPeriod[0].split('.').reverse().join('-');
        newPeriodFormatedTo = newPeriod[1]?.split('.').reverse().join('-');
    }

    if (periodToInput) periodToInput.value = '';

    if (periodFromInput && newPeriod && newPeriod[0]) periodFromInput.value = `${newPeriodFormatedFrom}`;
    if (periodToInput && newPeriod && newPeriod[1]) periodToInput.value = `${newPeriodFormatedTo}`;

    if (periodToInput && periodToInput.value) {
        let dataForm;

        if (formFilters) dataForm = serialize(formFilters, { hash: true });

        if (url && container) {
            if (calenddarContainer) calenddarContainer.classList.add('is-not-empty');
            getRequest(container, url, dataForm);
        }
    }
}

async function fetchClearCalendar(this: HTMLInputElement, event: any) {
    event.preventDefault();

    const target = event.delegateTarget as HTMLAnchorElement;
    const content = target.closest<HTMLElement>('.js-content-block');
    const container = content ? content.querySelector<HTMLElement>('.js-content') : null;
    const formFilters: HTMLFormElement | null = target.closest('.js-filters-form');
    const url = formFilters?.action;

    const calendarContainer = target.closest<HTMLElement>('.js-calendar-container');
    const periodFromInput = content?.querySelector<HTMLInputElement>('.js-period-input-from');
    const periodToInput = content?.querySelector<HTMLInputElement>('.js-period-input-to');

    if (periodToInput) periodToInput.value = '';
    if (periodFromInput) periodFromInput.value = '';

    let dataForm;

    if (formFilters) dataForm = serialize(formFilters, { hash: true });

    if (url && container) {
        if (calendarContainer) calendarContainer.classList.remove('is-not-empty');
        getRequest(container, url, dataForm);
    }
}

function init(container: Element | Document = document) {
    delegation = delegate(container, '.js-filters-form', 'submit', fetchData);
    delegation = delegate(container, '.js-filter-trigger', 'change', fetchData);
    // delegation = delegate(container, '.js-filter-calendar', 'change', fetchDataCalendar);
    delegation = delegate(container, '.js-clear-calendar', 'click', fetchClearCalendar);
    delegation = delegate(container, '.js-filter-clear', 'click', fetchData);
    container.querySelectorAll('.js-filter-calendar').forEach(calendar => {
        calendar.addEventListener('change', fetchDataCalendar);
    })
}

function destroy(container: Element | Document = document) {
    if (delegation) {
        delegation.destroy();
        delegation = null;
    }
}

const _module = { init, destroy };

export default _module;
