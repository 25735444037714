import axios from 'axios';
import serialize from 'form-serialize';
import select from './select';
import { getOffsetTop } from '../utils/dom';
import collapse from '../custom-elements/Collapse/Collapse';
import reveal from './reveal';
import pagination from './pagination';
import gsap from 'gsap';

const header = document.querySelector<HTMLElement>('.js-header');

export async function getRequest(
    container: HTMLElement | null,
    url: string,
    dataForm: any = '',
    newSetUrl: boolean = true,
) {
    container?.classList.add('is-loading');

    const content = container?.closest<HTMLElement>('.js-content-block');
    const formFilters: HTMLFormElement | null | undefined = content?.querySelector('.js-filters-form');
    let newUrl: any;

    if (dataForm && newSetUrl) {
        if (formFilters) newUrl = `${url}?${serialize(formFilters)}`;
        newUrl = newUrl.replace('/?', '?');
    } else if (!newSetUrl) {
        newUrl = url;
    }

    const response = await axios
        .get(url, {
            params: dataForm,
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
        })
        .then((response) => response.data)
        .then((data) => {
            if (container && data.data.listHtml) container.innerHTML = data.data.listHtml;
            if (newUrl) history.pushState(null, '', newUrl);

            const tl = gsap.timeline();
            tl.to('.a-second-container', {
                y: 0,
                duration: 0.8,
                opacity: 1,
            });

            if (container) {
                if (formFilters) {
                    window.scrollTo({
                        top: getOffsetTop(formFilters) - (header ? header.getBoundingClientRect().height : 0),
                        behavior: 'smooth',
                    });
                }

                const links = container.querySelectorAll('.js-get-new-page.is-disable, .js-get-new-page.is-active');
                links.forEach((item) => item.setAttribute('tabindex', '-1'));
            }
        })
        .catch((err) => {
            if (err.name !== 'AbortError') {
                throw err;
            }
        })
        .finally(() => {
            setTimeout(() => {
                container?.classList.remove('is-loading');
            }, 300);
            const selects = container?.querySelector('.js-dropdown'),
                searchSelects = container?.querySelector('.js-search-dropdown'),
                collapses = container?.querySelector('.js-collapse'),
                reveals = container?.querySelector('.js-reveal'),
                paginationsScroll = container?.querySelector('.js-get-new-page-with-scroll');

            if (selects) select.init();
            if (searchSelects) select.init();
            if (collapses) collapse.init();
            if (reveals) reveal.init();
            if (paginationsScroll) pagination.init();

            const suggestionContainer = formFilters?.querySelector('.js-autocomplete-list');
            suggestionContainer?.classList.remove('autocomplete-list--visible');
        });
}
