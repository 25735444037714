import delegate from 'delegate';
import { BaseResponse } from '../types';
import { getRequest } from './getRequestFilters';
import axios from 'axios';
import select from './select';
import collapse from '../custom-elements/Collapse/Collapse';
import reveal from './reveal';
import gsap from 'gsap';

type DocsResponse = BaseResponse & {
    data: {
        listHtml: string;
    };
};

let currentFetch: { abort: () => void; ready: Promise<DocsResponse> } | null;
let delegation: any;

async function fetchData(event: any) {
    event.preventDefault();

    const target = event.delegateTarget as HTMLAnchorElement;
    const content = target.closest<HTMLElement>('.js-content-block');
    const container = content ? content.querySelector<HTMLElement>('.js-content') : null;
    const url = target.href;

    if (container) {
        getRequest(container, url, '', false);
    }
}

let observerContent = new IntersectionObserver(
    (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const target = entry.target as HTMLLinkElement;
                const content = target.closest<HTMLElement>('.js-content-block');
                const container = content ? content.querySelector<HTMLElement>('.js-content') : null;
                const url = target.href;

                if (container) {
                    getRequestScroll(container, target, url);
                }
            }
        });
    },
    {
        threshold: 0.2,
    },
);

const getRequestScroll = async (container: HTMLElement | null, target: HTMLLinkElement, url: string) => {
    target?.classList.add('is-loading');

    const response = await axios
        .get(url, {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
        })
        .then((response) => response.data)
        .then((data) => {
            target?.classList.remove('is-loading');
            target.classList.remove('js-get-new-page-with-scroll');
            target.style.height = '0';
            target.style.overflow = 'hidden';

            if (container && data.data.listHtml) container.innerHTML += data.data.listHtml;
            const tl = gsap.timeline();
            tl.to('.a-second-container', {
                y: 0,
                duration: 0.8,
                opacity: 1,
            });
        })
        .catch((err) => {
            if (err.name !== 'AbortError') {
                throw err;
            }
        })
        .finally(() => {
            setTimeout(() => {
                target?.classList.remove('is-loading');

                const scrollContentTrigger = container?.querySelectorAll('a.js-get-new-page-with-scroll');
                scrollContentTrigger?.forEach((trigger) => observerContent.observe(trigger));
            }, 300);

            const selects = container?.querySelectorAll('select.js-dropdown'),
                collapses = container?.querySelector('.js-collapse'),
                reveals = container?.querySelector('.js-reveal');

            if (selects && selects.length > 0) {
                selects.forEach((select) => {
                    select.classList.remove('select--initialization');
                });

                const containerSelect = container?.querySelectorAll<HTMLElement>('.ss-main.js-dropdown');
                containerSelect?.forEach((select) => {
                    select.remove();
                });

                select.init();
            }

            if (collapses) collapse.init();
            if (reveals) reveal.init();
        });
};

function init(container: Element | Document = document) {
    delegation = delegate(container, 'a.js-get-new-page', 'click', fetchData);
    const links = container.querySelectorAll('.js-get-new-page.is-disable, .js-get-new-page.is-active');
    links.forEach((item) => item.setAttribute('tabindex', '-1'));

    const scrollContentTrigger = container.querySelectorAll('a.js-get-new-page-with-scroll');
    scrollContentTrigger.forEach((trigger) => observerContent.observe(trigger));
}

function destroy(container: Element | Document = document) {
    if (currentFetch) {
        currentFetch.abort();
        currentFetch = null;
    }

    if (delegation) {
        delegation.destroy();
        delegation = null;
    }
}

const _module = { init, destroy };

export default _module;
