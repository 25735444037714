import delegate from 'delegate';
import axios from 'axios';

let delegation: any;

async function fetchCaptchaUpdate(target: HTMLElement) {
    const captchaUrl = target.dataset.url;
    const captchaContainer = target.closest('.js-captcha-container');
    const captchaInput = captchaContainer?.querySelector<HTMLInputElement>('.js-captcha-input');
    const captchaImage = captchaContainer?.querySelector<HTMLImageElement>('.js-captcha-image');

    if (captchaUrl) {
        await axios
            .get(captchaUrl, {
                headers: { 'X-Requested-With': 'XMLHttpRequest' },
            })
            .then((response) => response.data)
            .then((data) => {
                if (captchaInput && data.data.captcha_value) {
                    captchaInput.value = data.data.captcha_value;
                }
                if (captchaImage && data.data.captcha_src) {
                    captchaImage.src = data.data.captcha_src;
                }
            })
            .catch((err) => {
                if (err.name !== 'AbortError') {
                    throw err;
                }
            });
    }
}

function updateCaptchaHandler(this: HTMLInputElement, event: any) {
    event.preventDefault();
    const target = event.delegateTarget as HTMLAnchorElement;
    fetchCaptchaUpdate(target);
}

function updateCaptchaWithSuccessForm(this: HTMLElement) {
    const updateCaptchaTrigger = this.querySelector<HTMLElement>('.js-update-captcha');
    if (updateCaptchaTrigger) fetchCaptchaUpdate(updateCaptchaTrigger);
}

function init(container: Element | Document = document) {
    delegation = delegate(container, '.js-update-captcha', 'click', updateCaptchaHandler);
    container.querySelectorAll('.js-update-captcha-form').forEach(form => {
        form.addEventListener('update-captcha', updateCaptchaWithSuccessForm);
    })
}

function destroy(container: Element | Document = document) {
    if (delegation) {
        delegation.destroy();
        delegation = null;
    }
    container.querySelectorAll('.js-update-captcha-form').forEach(form => {
        form.removeEventListener('update-captcha', updateCaptchaWithSuccessForm);
    })
}

const _module = { init, destroy };

export default _module;
