const header = document.querySelector<HTMLElement>('.js-header');

function setPositionClass(this: HTMLElement) {
    const container = this.closest('.js-list-popup-container');
    if (!container) return;

    const headerHeight = header?.offsetHeight;
    const popup = container?.querySelector<HTMLElement>('.js-list-popup');
    const popupHeight = popup?.offsetHeight;
    const popupTop = container.getBoundingClientRect().top;

    if (!this.classList.contains('is-active')) {
        return;
    }

    if (headerHeight && popupHeight) {
        if (popupTop - headerHeight <= popupHeight) {
            popup.classList.add('is-bottom');
        } else {
            popup.classList.remove('is-bottom');
        }
    }
}

function init(container: Element | Document = document) {
    container.querySelectorAll('.js-popup-position-trigger').forEach(el => {
        el.addEventListener('click', setPositionClass);
    });
}

function destroy(container: Element | Document = document) {
    document.querySelectorAll('.js-popup-position-trigger').forEach(el => {
        el.removeEventListener('click', setPositionClass);
    });
}

const _module = { init, destroy };

export default _module;
