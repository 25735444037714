/* global NODE_ENV */
import './webpack-imports';
import './sentry';
import './polyfills';
import './custom-elements';
import { lazyload } from './custom-elements/lazy-load';
// import barba from '@barba/core';
// // // Transitions
import { contentAnimation } from './animations/to-index';
import ToEditorTransition from './animations/to-editor';

// // // Views
import 'intersection-observer';
import './views/_base';
import IndexPageView from './views/index';
import EditorPageView from './views/editor';
// import SearchPageView from './views/search';
// import AboutPageView from './views/about';
// import CareerPageView from './views/career';
// import ContactsPageView from './views/contacts';
// import CompanyPageView from './views/company';
// import IndexPageView from './views/index';
// import EditorPageView from './views/editor';
// import TendersrPageView from './views/tenders';
// import TenderDetailPageView from './views/tenders-detail';
// import AshPageView from './views/ash';

import reveal from './inits/reveal';
import popups from './inits/lit-popup.js';
import outerPopups from './inits/outer-popups.js';
import { createMainSearchbar } from './components/main-searchbar';
import { createPageSearchbar } from './inits/page-searchbar';

import showMore from './inits/show-more';
import slider from './inits/slider';
import sliderVacancy from './inits/slider-vacancy';

import sliderGallery from './inits/editor-slider-gallery';
import navThemeObserver from './inits/nav-theme-observer';
import scrollToElement from './inits/scroll-to-element';
import scrollSpy from './inits/scroll-spy';
import asideNavReplacer from './inits/aside-nav-replacer';

import filters from './inits/filters';
import pagination from './inits/pagination';
import paginationCount from './inits/pagination-count';
import clearInput from './inits/clear-input';
import collapse from './custom-elements/Collapse/Collapse';
import textSlider from './inits/about-text-slider';
import sliderManagement from './inits/management-slider';
import sliderEnterprises from './inits/enterprises-slider';
import calendar from './inits/calendar';
import copyBlock from './inits/copy-block';
import printPage from './inits/print-page';
import tendersCollapse from './inits/tenders-collapse';
import simpleFilters from './inits/simple-filter';
import lightgallery from './inits/lightgallery';
import contactsRequest from './inits/contacts-request';
import flyAshRequest from './inits/fly-ash-request';
import stickyPollyfill from './inits/sticky-pollyfill';
import mapAsh from './inits/map-ash';
import gsap from 'gsap';
import popupPosition from './inits/popup-position';
import sliderPersons from './inits/slider-persons';
import sliderAsh from './inits/ash-slider';
import selectChanging from './inits/select-changing';
import captchaUpdate from './inits/captcha-update';
// import './views/sensors-page';

import zolaCalculator from './inits/zola-calculator';
import videoPopup from './inits/video-popup';
import emailOrPhoneInput from './inits/email-or-phone-input';
import problemSolvingAjaxSelectContent from './inits/problem-solving-ajax-select-content';
import lcPopup from './inits/lc-popup';
import select from './inits/select';
import invitation from './inits/invitation';

import calculateScrollbarWidth from '../modules/calculate-scrollbar-width';

var ua = window.navigator.userAgent.toLowerCase();
var isIe = /trident/gi.test(ua) || /msie/gi.test(ua);

gsap.config({ nullTargetWarn: false });
document.addEventListener('DOMContentLoaded', () => {
    calculateScrollbarWidth();
    document.documentElement.classList.add('js-ready');
    lazyload.update();
    contentAnimation();
    popups.init();
    outerPopups.init();
    const mainSearchForm = document.querySelector('form.js-main-searchbar') as HTMLFormElement | null;
    if (mainSearchForm) {
        createMainSearchbar(mainSearchForm);
    }
    const pageSearchForm = document.querySelector('form.js-page-searchbar') as HTMLFormElement;
    if (pageSearchForm) {
        createPageSearchbar(pageSearchForm);
    }
    stickyPollyfill.init();
    reveal.init();
    select.init();
    lcPopup.init();
    showMore.init();
    slider.init();
    sliderVacancy.init();
    sliderGallery.init();
    navThemeObserver.init();
    scrollToElement.init();
    scrollSpy.init();
    asideNavReplacer.init();
    invitation.init();

    filters.init();
    pagination.init();
    paginationCount.init();
    clearInput.init();
    collapse.init();
    textSlider.init();
    sliderManagement.init();
    sliderEnterprises.init();
    sliderAsh.init();
    calendar.init();
    copyBlock.init();
    printPage.init();
    tendersCollapse.init();
    simpleFilters.init();
    lightgallery.init();
    contactsRequest.init();
    popupPosition.init();
    sliderPersons.init();
    flyAshRequest.init();
    mapAsh.init();
    selectChanging.init();
    captchaUpdate.init();
    emailOrPhoneInput.init();
    problemSolvingAjaxSelectContent.init();
    zolaCalculator.init();
    videoPopup.init();

    if (isIe) {
        const chartContainer = document.querySelector('.js-chart-container');
        const ieAlert = document.querySelector('.js-ie-alert');
        chartContainer?.classList.add('is-hide');
        ieAlert?.classList.add('is-show');
    } else {
        import('./inits/sensors').then((sensors) => sensors.default.init(document));
    }

    // SearchPageView();
    // AboutPageView();
    // CareerPageView();
    // ContactsPageView();
    // CompanyPageView();
    // TendersrPageView();
    // TenderDetailPageView();
    // AshPageView();

    // barba.init({
    //     transitions: [
    //         // ToIndexTransition,
    //         // ToEditorTransition
    //     ],
    //     views: [IndexPageView, EditorPageView, AboutPageView],
    //     debug: NODE_ENV === 'development',
    //     timeout: 5000,
    // });
});

module.hot?.accept();
