import LitPopup from 'lit-popup';
import barba from '@barba/core';

import { lockBodyScroll, unlockBodyScroll } from '../utils/lock-body-scroll';

const map = new Map();

async function clearForm(form) {
    const [{ clearInputs }, { clearMessages, clearInputErrors }] = await Promise.all([
        fetchAjaxFormSender(),
        fetchValidator(),
    ]);

    const inputs = Array.from(
        form.querySelectorAll('input[name]:not([type="submit"]):not([type="reset"]), select[name], textarea[name]'),
    );

    clearInputs(inputs);
    inputs.forEach((input) => clearInputErrors(input));
    clearMessages(form);
}

function init(container = document) {
    const elements = Array.from(container.querySelectorAll('.js-popup'));
    elements.forEach((el) => {
        const name = el.dataset.litPopup;
        let wasBodyLocked = false;
        if (!name) {
            throw new Error('[lit-popup] Name should be provided.');
        }

        const lockScroll = () => {
            wasBodyLocked = document.body.classList.contains('no-scroll');

            lockBodyScroll();
        };

        const unlockScroll = () => {
            unlockBodyScroll();
        };

        const popup = new LitPopup(name, {
            onOpen: () => {
                lockScroll();
                popup.el.classList.add('is-open');
            },
            onCloseComplete: () => {
                unlockScroll();
                popup.el.classList.remove('is-open');
                let form = el.querySelector('form[data-clear-on-popup-close]');
                if (form) {
                    clearForm(form);
                }
            },
        });

        barba.hooks.leave(() => {
            if (popup.isOpen) {
                popup.close();
            }
        });

        map.set(el, popup);
    });
}

function destroy(container = document) {
    const elements = Array.from(container.querySelectorAll('[data-lit-popup]'));

    elements.forEach((el) => {
        const popup = map.get(el);

        if (popup) {
            popup.destroy();
            map.delete(el);
        }
    });

    window.popupsInited = false;
}

function getInstanceByElement(element) {
    return element ? map.get(element) : undefined;
}

const _module = { init, destroy, getInstanceByElement };

export default _module;
