let mapContainer: HTMLElement | null;
let mapFilters: NodeListOf<HTMLInputElement> | undefined;
let mapPoints: NodeListOf<HTMLElement> | undefined;
let mapRegions: NodeListOf<HTMLElement> | undefined;
let mapPopupPoints: NodeListOf<HTMLElement> | undefined;
let activeFilters: string[] = [];

function setAloneFilter(el: HTMLInputElement) {
    mapFilters?.forEach(item => {
        if (item !== el) {
            item.checked = false;
        }
    })
}

function setActiveFilters() {
    activeFilters = [];
    mapFilters?.forEach(item => {
        if (item.checked && item.dataset.mapFilter) activeFilters.push(item.dataset.mapFilter);
    });
}

function setActiveRegions() {
    mapRegions?.forEach(region => {
        const regionPoints = region?.querySelectorAll('.js-map-point');
        let countActivePoints = 0;
        regionPoints.forEach(point => !point.classList.contains('is-hide') ? countActivePoints++ : '');
        if (countActivePoints === 0) {
            region.classList.add('is-hide')
        } else {
            region.classList.remove('is-hide')
        }
    });
}

function setActivePoins() {
    if (activeFilters.length === 0) {
        mapPoints?.forEach(point => point.classList.remove('is-hide'));
        return;
    }

    mapPoints?.forEach(point => point.classList.add('is-hide'));
    mapPoints?.forEach(point => {
        const valueFilter = point.dataset.mapPoint;
        const valueFilterArr = valueFilter?.split(',');

        activeFilters.forEach(activeFilter => {
            if (valueFilterArr && valueFilterArr.indexOf(activeFilter) > -1) {
                point.classList.remove('is-hide');
            }
        })
    });
}

function setActivePopupPoins() {
    if (activeFilters.length === 0) {
        mapPopupPoints?.forEach(point => point.classList.remove('is-hide'));
        return;
    }

    mapPopupPoints?.forEach(point => point.classList.add('is-hide'));
    mapPopupPoints?.forEach(point => {
        const valueFilter = point.dataset.mapPoint;
        const valueFilterArr = valueFilter?.split(',');

        activeFilters.forEach(activeFilter => {
            if (valueFilterArr && valueFilterArr.indexOf(activeFilter) > -1) {
                point.classList.remove('is-hide');
            }
        })
    });
}

function filterPoints(this: HTMLInputElement) {
    mapContainer = this.closest('.js-map-container');
    mapFilters = mapContainer?.querySelectorAll('.js-map-filter');
    mapPoints = mapContainer?.querySelectorAll('.js-map-point');
    mapRegions = mapContainer?.querySelectorAll('.js-map-region');
    mapPopupPoints = mapContainer?.querySelectorAll('.js-map-popup-point');

    setAloneFilter(this);
    setActiveFilters();
    setActivePoins();
    setActiveRegions();
    setActivePopupPoins();
}

function init(container: Element | Document = document) {
    const mapFiltersArr = Array.from(container.querySelectorAll('.js-map-filter'));
    if (mapFiltersArr.length) {
        mapFiltersArr.forEach((link) => link.addEventListener('change', filterPoints));
    }

    if (window.matchMedia('(max-width: 1024px').matches) {
        const mapInner = container.querySelector('.js-map-inner');
        if (mapInner) {
            const mapWidth = mapInner.querySelector('svg')?.clientWidth;
            if (mapWidth) mapInner.scrollTo(mapWidth / 4, 0);
        }
    }
}

function destroy(container: Element | Document = document) {
    const mapFiltersArr = Array.from(container.querySelectorAll('.js-map-filter'));
    if (mapFiltersArr.length) {
        mapFiltersArr.forEach((link) => link.removeEventListener('change', filterPoints));
    }
}

const _module = { init, destroy };

export default _module;
