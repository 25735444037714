import debounce from 'lodash.debounce';
import axios from 'axios';
import barba from '@barba/core';

type BaseResponse = {
    success: boolean;
    message?: string;
};

type Suggestion = {
    title: string;
    url: string;
};

type SearchSuggestionsResponse = BaseResponse & {
    data: {
        suggestions: Suggestion[];
    };
};

const LOADING_CLASS = 'searchbar--loading';
const VISIBLE_SUGGESTIONS_CLASS = 'autocomplete-list--visible';

const API = {
    GET_SEARCH_SUGGESTIONS: `/local/ajax/search_title_index.php`,
};

export const createMainSearchbar = (form: HTMLFormElement) => {
    const input = form.querySelector('input');
    const suggestionContainer = form.querySelector('.js-autocomplete-list');
    let suggestionLinks: NodeListOf<HTMLElement>;
    let activeIndex = 0;

    const isValidInput = (el: HTMLInputElement) => el.value.trim().length > 0;

    const setLoadingState = () => {
        form.classList.add(LOADING_CLASS);
    };

    const removeLoadingState = () => {
        form.classList.remove(LOADING_CLASS);
    };

    const showSuggestions = () => {
        if (!suggestionContainer) return;
        suggestionContainer.classList.add(VISIBLE_SUGGESTIONS_CLASS);
    };

    const hideSuggestions = () => {
        if (!suggestionContainer) return;
        suggestionContainer.classList.remove(VISIBLE_SUGGESTIONS_CLASS);
    };

    const createSuggestions = (...suggestions: Suggestion[]) => {
        if (!suggestionContainer) return;

        const template = suggestions
            .map(
                (suggestion) => `
                    <li class="autocomplete-list__item" data-barba-prevent="all">
                        <a href="${suggestion.url}" class="autocomplete-list__link js-autocomplete-link">${suggestion.title}</a>
                    </li>
                `,
            )
            .join('');

        suggestionContainer.innerHTML = template;
        suggestionContainer.scrollTop = 0;
    };

    const errorMessage = () => {
        if (!suggestionContainer) return;

        const template = `
        <li class="autocomplete-list__item" data-barba-prevent="all">
            <div class="autocomplete-list__link">Ничего не найдено</div>
        </li>`;

        suggestionContainer.innerHTML = template;
        suggestionContainer.scrollTop = 0;
    };

    const closeOnOutsideClick = (event: any) => {
        if (event.target && !form.contains(event.target)) {
            hideSuggestions();
        }
    };

    const setActiveLink = (e: KeyboardEvent) => {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault();
            if (input && suggestionContainer && suggestionContainer.classList.contains(VISIBLE_SUGGESTIONS_CLASS)) {
                suggestionLinks = suggestionContainer.querySelectorAll('.js-autocomplete-link');

                if (suggestionLinks.length > 0) {
                    if (activeIndex >= 0) {
                        suggestionLinks[activeIndex].classList.remove('is-active');
                    }

                    if (e.key === 'ArrowDown') {
                        if (activeIndex === suggestionLinks.length - 1) {
                            suggestionLinks[0].classList.add('is-active');
                            suggestionContainer.scrollTop = 0;
                            activeIndex = 0;
                        } else {
                            suggestionLinks[activeIndex + 1].classList.add('is-active');

                            const topPosition = suggestionLinks[activeIndex + 1].offsetTop;
                            const conteinerHeight = suggestionContainer.getBoundingClientRect().height;

                            const elementRect = suggestionLinks[activeIndex + 1].getBoundingClientRect();
                            const containerRect = suggestionContainer.getBoundingClientRect();
                            if (!(elementRect.top >= containerRect.top && elementRect.bottom <= containerRect.bottom)) {
                                suggestionContainer.scrollTop =
                                    topPosition +
                                    suggestionLinks[activeIndex + 1].getBoundingClientRect().height -
                                    conteinerHeight;
                            }
                            activeIndex += 1;
                        }
                    } else if (e.key === 'ArrowUp') {
                        if (activeIndex <= 0) {
                            suggestionLinks[suggestionLinks.length - 1].classList.add('is-active');
                            suggestionContainer.scrollTop = suggestionContainer.scrollHeight;
                            activeIndex = suggestionLinks.length - 1;
                        } else {
                            suggestionLinks[activeIndex - 1].classList.add('is-active');

                            const topPosition = suggestionLinks[activeIndex - 1].offsetTop;

                            if (topPosition <= suggestionContainer.scrollTop) {
                                suggestionContainer.scrollTop = topPosition;
                            }

                            activeIndex -= 1;
                        }
                    }

                    if (input) input.value = `${suggestionLinks[activeIndex].textContent}`;
                }
            }
        }
    };

    const fetchSuggestions = async (e: KeyboardEvent) => {
        if (!input) return;
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') return;

        hideSuggestions();
        setLoadingState();

        if (isValidInput(input) && input.value.length > 2) {
            try {
                const { data } = await axios.get<SearchSuggestionsResponse>(
                    `${API.GET_SEARCH_SUGGESTIONS}?q=${encodeURIComponent(input.value)}`,
                    { responseType: 'json', params: { ajax_call: 'y' } },
                );
                if (data.data.suggestions) {
                    createSuggestions(...data.data.suggestions);
                } else {
                    errorMessage();
                }

                showSuggestions();
                activeIndex = -1;
            } finally {
                removeLoadingState();
            }
        }
    };

    const debouncedFetchSuggestions = debounce(fetchSuggestions, 500);

    const onSubmit = (event: any) => {
        event.preventDefault();

        if (!input) return;
        if (input.value === '') return;

        form.classList.add('is-load');
        if (isValidInput(input)) {
            input.value = input.value.trim();
            barba.force(form.action + `?q=${input.value}`);
        }
    };

    const init = () => {
        form.setAttribute('novalidate', 'novalidate');
        form.addEventListener('submit', onSubmit);
        document.addEventListener('click', closeOnOutsideClick);

        if (input) {
            input.addEventListener('keydown', debouncedFetchSuggestions);
            input.addEventListener('keydown', setActiveLink);
        }
    };

    const destroy = () => {
        form.removeEventListener('submit', onSubmit);
        document.removeEventListener('click', closeOnOutsideClick);

        if (input) {
            input.removeEventListener('keydown', debouncedFetchSuggestions);
            input.removeEventListener('keydown', setActiveLink);
        }
    };

    init();

    return { destroy } as const;
};
