import delegate from 'delegate';

let delegation: any;

async function clearInput(this: HTMLInputElement, event: any) {
    event.preventDefault();

    const target = event.delegateTarget as HTMLAnchorElement;

    const parent = target.closest('.js-clear-inner');
    if (parent) {
        const input = parent.querySelector<HTMLInputElement>('.js-clear-input');
        if (input) input.value = '';

        const suggestionContainer = parent.querySelector('.js-autocomplete-list');
        suggestionContainer?.classList.remove('autocomplete-list--visible');
    }
}

function init(container: Element | Document = document) {
    delegation = delegate(container, '.js-clear-input-btn', 'click', clearInput);
}

function destroy(container: Element | Document = document) {
    if (delegation) {
        delegation.destroy();
        delegation = null;
    }
}

const _module = { init, destroy };

export default _module;
