import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

function generateYoutubeEmbedURL(id: string) {
    const query = '?rel=0&showinfo=0&autoplay=1';
    return `https://www.youtube.com/embed/${id}${query}`;
}

let iframe: HTMLElement;
let iframeContainer: HTMLElement | null;
let videoEl: NodeListOf<HTMLElement>;

function createIframe(id: string, isYoutube: boolean) {
    let iframeEL;
    if (isYoutube) {
        iframeEL = document.createElement('iframe');
        iframeEL.classList.add('video');
        iframeEL.classList.add('video-iframe');
        iframeEL.setAttribute('allowfullscreen', '');
        iframeEL.setAttribute('allow', 'autoplay');
        iframeEL.setAttribute('src', generateYoutubeEmbedURL(id));
    } else {
        iframeEL = document.createElement('video');
        const source = document.createElement('source');
        iframeEL.classList.add('video');
        iframeEL.setAttribute('allowfullscreen', '');
        iframeEL.setAttribute('autoplay', '');
        iframeEL.setAttribute('controls', '');
        source.setAttribute('src', id);
        iframeEL.appendChild(source);
    }
    return iframeEL;
}

function onClick(this: HTMLElement, event: Event) {
    event.preventDefault();
    iframeContainer = document.querySelector<HTMLElement>('.js-video-container');
    const { videoId } = this.dataset;
    const { videoSrc } = this.dataset;

    if (videoId) {
        iframe = createIframe(videoId, true);
    }

    if (videoSrc) {
        iframe = createIframe(videoSrc, false);
    }

    if (iframeContainer) {
        iframeContainer.appendChild(iframe);
        const popup = iframeContainer.closest<LitPopupElement>('.lit-popup');

        popup?.addEventListener('close-complete', () => {
            if (iframeContainer) iframeContainer.innerHTML = '';
        });
    }
}

function init(container = document) {
    videoEl = container.querySelectorAll<HTMLElement>('.js-video-play');
    videoEl.forEach((el) => {
        el.addEventListener('click', onClick);
    });
}

function destroy(container = document) {
    videoEl.forEach((el) => el.removeEventListener('click', onClick));
}

const _module = { init, destroy };

export default _module;
