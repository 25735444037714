import axios from 'axios';

let arrTypeAppealSelectOptions: HTMLOptionElement[] = [];
let arrBlanksSelectOptions: HTMLOptionElement[] = [];

function setVisibleBlanks(show: boolean) {
    const blanksMessage = document.querySelector<HTMLElement>('.js-blanks-message');
    const blanksContainer = document.querySelector<HTMLElement>('.js-select-documents-container');

    if (blanksMessage && blanksContainer) {
        if (show) {
            blanksMessage.style.display = '';
            blanksContainer.style.display = '';
        } else {
            blanksMessage.style.display = 'none';
            blanksContainer.style.display = 'none';
        }
    }
}

function setPlaceholder(select: HTMLSelectElement) {
    const selectPlaceholder = document.createElement('option');
    selectPlaceholder.setAttribute('data-placeholder', 'true');
    selectPlaceholder.setAttribute('label', 'placeholder');
    select.append(selectPlaceholder);
}

function setOptionsSelect(
    select: HTMLSelectElement | null,
    arrayOptions: HTMLOptionElement[],
    activeValues: string[] | undefined,
) {
    if (select) {
        select.innerHTML = '';

        if (select.dataset.namePlaceholder) setPlaceholder(select);

        arrayOptions.forEach((item) => {
            const optionValue = item.dataset.value ? item.dataset.value : item.value;
            if (activeValues && optionValue && activeValues.includes(optionValue)) {
                select.append(item);
            }
        });

        select.dispatchEvent(new Event('update'));
    }
}

function sortBlanks() {
    const typeAppealSelect = document.querySelector<HTMLSelectElement>(`[data-changing-select="faq-type"]`);
    const citySelect = document.querySelector<HTMLSelectElement>(`[data-set-select="faq-type"]`);
    const blanksSelect = document.querySelector<HTMLSelectElement>(`.js-faq-blanks`);
    const formMessage = document.querySelector<HTMLElement>(`.js-faq-form-message`);
    const formInputText = document.querySelector<HTMLInputElement>(`.js-faq-form-input`);
    const typeAppealValue = typeAppealSelect?.options[typeAppealSelect.selectedIndex]?.dataset.value;
    const cityValue = citySelect?.options[citySelect.selectedIndex]?.dataset.value;

    // показать сообщение и подставить значение в input
    axios
        .get('/local/ajax/faqCity.php', {
            params: {
                id: typeAppealValue,
                city: cityValue
            },
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
        })
        .then((response) => response.data)
        .then((data) => {
            if (formMessage) {
                formMessage.innerHTML = '';
                formMessage.style.display = 'none';
            }
            if (formInputText) {
                formInputText.value = '';
            }

            if (data) {
                let templateText;
                let messageText;
                if (
                    data[cityValue] &&
                    data[cityValue]['UF_TEXT_CITY'] !== undefined &&
                    data[cityValue]['UF_CITY_UF_TEXT_DEF'] == 0
                ) {
                    templateText = data[cityValue]['UF_TEXT_CITY'];
                }
                if (
                    data[cityValue] &&
                    data[cityValue]['UF_TEXT_HELP_CITY'] !== undefined &&
                    data[cityValue]['UF_TEXT_HELP_DEF'] == 0
                ) {
                    messageText = data[cityValue]['UF_TEXT_HELP_CITY'];
                }

                if (formMessage && messageText) {
                    formMessage.innerHTML = messageText;
                    formMessage.style.display = 'block';
                }

                if (formInputText && templateText) {
                    formInputText.value = templateText;
                }
            }
        })
        .catch((err) => {
            if (err.name !== 'AbortError') {
                throw err;
            }
        });

    // сортируем бланки
    if (window.formJson && citySelect && typeAppealSelect) {
        const arrBlanks = window.formJson[cityValue][typeAppealValue];

        if (arrBlanks && arrBlanks.length) {
            setVisibleBlanks(true);
        } else {
            setVisibleBlanks(false);
            return;
        }

        setOptionsSelect(blanksSelect, arrBlanksSelectOptions, arrBlanks);
    }
}

function setActivOptionTypesSelect(this: HTMLLinkElement, e: Event) {
    e.preventDefault();
    const linkValue = this.dataset.typeValue;
    const changingSelect = document.querySelector<HTMLSelectElement>(`[data-changing-select="faq-type"]`);

    if (changingSelect) {
        for (let i = 0; i < changingSelect.options.length; i++) {
            const optionValue = changingSelect.options[i].dataset.value;
            if (optionValue && linkValue && optionValue === linkValue) {
                changingSelect.options[i].selected = true;
            }
        }
        changingSelect.dispatchEvent(new Event('update'));
    }

    sortBlanks();
}

function changeTypeAppealSelect(this: HTMLSelectElement) {
    const setSelect = this.dataset?.setSelect;
    const setValues = this.options[this.selectedIndex]?.dataset.setValue?.split(',');
    const changingSelect = document.querySelector<HTMLSelectElement>(`[data-changing-select="${setSelect}"]`);

    // сортируем селект
    setOptionsSelect(changingSelect, arrTypeAppealSelectOptions, setValues);

    // бланки
    if (changingSelect && changingSelect.selectedIndex > 0) {
        sortBlanks();
    } else if (changingSelect && changingSelect.selectedIndex === 0) {
        setVisibleBlanks(false);
    }
}

function init(container: HTMLElement | Document = document) {
    const selectCity = container.querySelector<HTMLSelectElement>('.js-dropdown-changing');
    const selectTypeAppeal = container.querySelector<HTMLSelectElement>('[data-changing-select="faq-type"]');
    const blanksSelect = container.querySelector<HTMLSelectElement>(`.js-faq-blanks`);
    const formMessage = document.querySelector<HTMLElement>(`.js-faq-form-message`);

    if (selectTypeAppeal) {
        for (let i = 0; i < selectTypeAppeal.options.length; i++) {
            arrTypeAppealSelectOptions.push(selectTypeAppeal.options[i]);
        }
    }

    if (blanksSelect) {
        for (let i = 0; i < blanksSelect.options.length; i++) {
            arrBlanksSelectOptions.push(blanksSelect.options[i]);
        }
    }

    if (selectCity) {
        const setTypeSelect = changeTypeAppealSelect.bind(selectCity);
        setTypeSelect();
    }

    selectCity?.addEventListener('change', changeTypeAppealSelect);
    selectTypeAppeal?.addEventListener('change', sortBlanks);

    if (formMessage) formMessage.style.display = 'none';
}

function destroy(container: HTMLElement | Document = document) {
    const selectCity = container.querySelector<HTMLSelectElement>('.js-dropdown-changing');
    const selectTypeAppeal = container.querySelector<HTMLSelectElement>('[data-changing-select="faq-type"]');

    selectCity?.removeEventListener('change', changeTypeAppealSelect);
    selectTypeAppeal?.removeEventListener('change', sortBlanks);

    arrTypeAppealSelectOptions = [];
    arrBlanksSelectOptions = [];
}

const _module = { init, destroy };

export default _module;
