import Swiper, { Navigation, Pagination } from 'swiper';
import 'objectFitPolyfill';

window.objectFitPolyfill();

Swiper.use([Navigation, Pagination]);

const map = new WeakMap<HTMLElement, any>();

function initNavigation(container: HTMLElement | null) {
    if (container)
        container.innerHTML = `
        <button class="js-ash-slider-prev button button__round slider-btn slider-btn--prev" aria-label="предыдущий слайд">
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.3334 1.3335L6.00008 6.00016L1.66675 1.3335" stroke="#1F1F22" stroke-width="1.5" stroke-linecap="square"/>
            </svg>
        </button>
        <button class="js-ash-slider-next button button__round slider-btn slider-btn--next" aria-label="следующий слайд">
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.3334 1.3335L6.00008 6.00016L1.66675 1.3335" stroke="#1F1F22" stroke-width="1.5" stroke-linecap="square"/>
            </svg>
        </button>
    `;
}

function init(container: HTMLElement | Document = document) {
    const ashSliders = Array.from(container.querySelectorAll<HTMLElement>('.js-ash-slider'));

    ashSliders.forEach((slider) => {
        const slides = slider.querySelectorAll('.swiper-slide');
        let btnPrev: HTMLElement | null | undefined;
        let btnNext: HTMLElement | null | undefined;

        const navigation = slider.querySelector<HTMLElement>('.js-navigation');
        if (slides.length > 1) {
            if (navigation) initNavigation(navigation);
            btnPrev = navigation?.querySelector('.js-ash-slider-prev');
            btnNext = navigation?.querySelector('.js-ash-slider-next');

            const swiper = new Swiper(slider, {
                slidesPerView: 1,
                slidesPerGroup: 1,
                spaceBetween: 10,
                speed: 800,
                navigation: {
                    nextEl: btnNext,
                    prevEl: btnPrev,
                    disabledClass: 'is-disable',
                },
                pagination: {
                    el: '.js-pagination',
                    type: 'custom',
                    renderCustom(_swiper, current, total) {
                        return `${current} / ${total}`;
                    },
                },
                breakpoints: {
                    650: {
                        spaceBetween: 18,
                    },
                    1441: {
                        spaceBetween: 48,
                    },
                },
                on: {
                    init: () => {
                        const elements = document.querySelectorAll('.ash-slide__inner img');
                        if (elements) objectFitPolyfill(elements);
                    },
                },
            });
            map.set(slider, swiper);
        }
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-ash-slider')).forEach((el) => {
        const slider = map.get(el);

        if (slider) {
            slider.destroy();
            map.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;
