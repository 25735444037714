import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';

export default (el) => {
    let _onInputChange = onInputChange.bind(this);

    let files = [];
    let isUploading = false;

    const input = el.querySelector('input[type="file"]');
    const uiContainer = el.querySelector('.js-file-upload-ui');
    const form = el.closest('.js-form-with-upload');

    if (!input) {
        throw new Error('Input element not found');
    }

    input.classList.add('visually-hidden');

    let uppy = new Uppy({
        pretty: false,
        autoProceed: true,
        allowMultipleUploadBatches: true,
    });

    uppy.use(XHRUpload, {
        endpoint: el.dataset.url,
        formData: true,
        fieldName: input.name,
        headers: {
            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '',
        },
    });

    uppy.on('upload', (data) => {
        isUploading = true;
        el.dispatchEvent(new CustomEvent('upload', { detail: data, composed: true }));
    });

    uppy.on('upload-success', (file, response) => {
        files.push(file);
        el.dispatchEvent(new CustomEvent('upload-success', { detail: file, composed: true }));
        updateUI();
    });

    uppy.on('complete', (data) => {
        isUploading = false;
        el.dispatchEvent(new CustomEvent('complete', { detail: data, composed: true }));
    });

    input.addEventListener('change', _onInputChange);

    function onInputChange(event) {
        const inputFiles = Array.from(event.target.files);

        inputFiles.forEach((file) => {
            try {
                uppy.addFile({
                    name: file.name,
                    type: file.type,
                    data: file,
                });
            } catch (err) {
                if (err.isRestriction) {
                    console.error('Restriction error:', err);
                }
                throw err;
            }
        });
    }

    function updateUI() {
        if (uiContainer) {
            const uiTemplateHTML = uiTemplate();
            uiContainer.innerHTML = '';
            uiContainer.innerHTML = uiTemplateHTML;
        }

        const removeButtons = el.querySelectorAll('.js-remove-file')
        removeButtons.forEach(btn => {
            btn.addEventListener('click', removeFileById);
        })
    }

    function uiTemplate() {
        let html;
        return html = `
            <div>
                ${files.length > 0
                    ? `
                        <ul class="list-unstyled file-upload-list">
                            ${files.map(
                                (file) => `
                                    <li class="file-upload-list__item">
                                        <div class="file-upload-item">
                                            <span class="file-upload-item__name">${file.name}</span>
                                            <button
                                                type="button"
                                                class="file-upload-item__remove-btn js-remove-file"
                                                aria-label="Удалить файл" data-id-file="${file.id}"
                                            >
                                                <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.5 4V2.5C4.5 1.94772 4.94772 1.5 5.5 1.5H9.5C10.0523 1.5 10.5 1.94772 10.5 2.5V4M0 4.5H15M1.5 4.5V14.5C1.5 15.0523 1.94772 15.5 2.5 15.5H12.5C13.0523 15.5 13.5 15.0523 13.5 14.5V4.5" stroke="#1F1F22" stroke-opacity="0.5" stroke-width="1.5"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </li>
                                `
                            )}
                        </ul>
                    `
                    : ''}
            </div>
        `;
    }

    function removeFileById(this) {
        const id = this.dataset.idFile;
        uppy.removeFile(id);
        files = files.filter((file) => file.id !== id);

        if (input) {
            input.value = '';
        }

        setTimeout(() => {
            updateUI();
        }, 1);
    }

    function clear() {
        files.forEach((file) => {
            uppy.removeFile(file.id);
        });
        files = [];

        setTimeout(() => {
            updateUI();
        }, 1);
    }

    if (form) {
        form.addEventListener('success', () => {
            clear();
            uppy.cancelAll();
        });
    }
};
