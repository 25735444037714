import './Collapse.scss';
import scrollTo from '../../inits/scrollTo';

const CONTAINER_SELECTOR = '.js-collapse';
const TRIGGER_SELECTOR = '.js-collapse-trigger';

function onClick(this: Element, e: Event): void {
    e.preventDefault();
    if (this.classList.contains('js-contacts-request') || this.classList.contains('js-fly-ash-request')) return;

    let setParentHeight = false;
    if (this.classList.contains('js-collapse-parent-trigger')) setParentHeight = true;

    setCollapse(this, setParentHeight);
}

function closeCollapse(trigger: Element) {
    const collapseEl = trigger.closest('.js-collapse');
    const content = collapseEl?.querySelector<HTMLElement>('.js-collapse-content');

    trigger.setAttribute('aria-expanded', 'false');
    if (collapseEl) collapseEl.setAttribute('collapsed', '');
    if (content) content.style.height = '0px';
}

function setCollapse(trigger: Element, setParentHeight: boolean) {
    const isTriggerActive = trigger.getAttribute('aria-expanded') === 'true';
    if (
        trigger.closest('.js-collapse-group') &&
        !trigger.classList.contains('js-collapse-not-group')
    ) {
        const collapseGroup : Element | null = trigger.closest('.js-collapse-group');
        if (collapseGroup) {
            const collapses = Array.from(collapseGroup?.querySelectorAll(TRIGGER_SELECTOR));
            collapses?.forEach(collapse => {
                const collapseEl = collapse.closest('.js-collapse');
                const content = collapseEl?.querySelector<HTMLElement>('.js-collapse-content');

                if (collapse === trigger || trigger.classList.contains('js-collapse-trigger-group')) {
                    if (!trigger.classList.contains('js-collapse-trigger-group')) {
                        const isExpanded = collapse.getAttribute('aria-expanded') === 'true';
                        collapse.setAttribute('aria-expanded', isExpanded ? 'false' : 'true');

                        if (isExpanded) {
                            collapse.setAttribute('aria-expanded', 'false');
                            if (collapseEl) collapseEl.setAttribute('collapsed', '');
                            if (content) content.style.height = '0px';
                        } else {
                            collapse.setAttribute('aria-expanded', 'true');
                            if (collapseEl) collapseEl.removeAttribute('collapsed');
                            if (content) content.style.height = `${content.scrollHeight}px`;
                        }
                    }

                } else {
                    if (isTriggerActive) {
                        return
                    }


                    let counterActivecollapse = 0;
                    const allSubCollapse = Array.from(collapseGroup.querySelectorAll(TRIGGER_SELECTOR))
                        .filter(collapse => collapse != trigger);

                    allSubCollapse.forEach(collapse => {
                        if (collapse.getAttribute('aria-expanded') === 'true') counterActivecollapse++;
                    });

                    collapse.setAttribute('aria-expanded', 'false');
                    if (collapseEl) collapseEl.setAttribute('collapsed', '');
                    if (content) content.style.height = '0px';

                    if (counterActivecollapse != 0) {
                        setTimeout(() => {
                            scrollTo(trigger);
                        }, 300);
                    }

                }
            });
        }
    } else {

        if (
            isTriggerActive &&
            (trigger.classList.contains('js-contacts-request-group') ||
            trigger.classList.contains('js-fly-ash-request[aria-expanded="false"]'))
        ) {
            return
        }

        const isExpanded = trigger.getAttribute('aria-expanded') === 'true';
        const collapseEl = trigger.closest('.js-collapse');
        const content = collapseEl?.querySelector<HTMLElement>('.js-collapse-content');
        const form = collapseEl?.querySelector<HTMLElement>('.js-ajax-form');
        const triggers = collapseEl?.querySelectorAll<HTMLElement>(TRIGGER_SELECTOR);
        const textInner = trigger.querySelector<HTMLElement>('.js-collapse-text');
        let hideText, showText;
        if (textInner) {
            hideText = textInner.dataset.hideText;
            showText = textInner.dataset.showText;
        }

        if (isExpanded) {
            trigger.setAttribute('aria-expanded', 'false');
            if (collapseEl) collapseEl.setAttribute('collapsed', '');
            if (content) content.style.height = '0px';
            if (textInner) textInner.textContent = `${showText}`;

            triggers?.forEach(trigger => {
                if (trigger != trigger) {
                    trigger.setAttribute('aria-expanded', 'false');
                }
            })

            if (form) form.classList.remove('js-ajax-form--success');
            if (form) form.classList.remove('js-ajax-form--error');

        } else {
            trigger.setAttribute('aria-expanded', 'true');
            if (collapseEl) collapseEl.removeAttribute('collapsed');
            if (content) content.style.height = `${content.scrollHeight}px`;
            if (textInner) textInner.textContent = `${hideText}`;

            triggers?.forEach(trigger => {
                if (trigger != trigger) {
                    trigger.setAttribute('aria-expanded', 'true');
                }
            })
        }

        if (setParentHeight) {
            const collapseParent = collapseEl?.closest('.js-collapse-parent');
            const contentParent = collapseParent?.querySelector<HTMLElement>('.js-collapse-content-parent');
            // const contentParentInner = contentParent?.querySelector<HTMLElement>('.contacts__inner');
            const contentParentInner = contentParent?.querySelector<HTMLElement>('.js-collapse-parent-inner') ? contentParent?.querySelector<HTMLElement>('.js-collapse-parent-inner') : contentParent?.querySelector<HTMLElement>('.contacts__inner');

            if (trigger.getAttribute('aria-expanded')) {
                if (contentParent) {
                    contentParent.style.height = 'auto';
                }
            } else {
                setTimeout(() => {
                    if (contentParent) contentParent.style.height = `${contentParentInner?.scrollHeight}px`;
                }, 500);
            }
        }
    }
}

export function initCollapse(element: Element, triggerSelector = TRIGGER_SELECTOR) {
    const triggers = Array.from(element.querySelectorAll(triggerSelector));
    triggers.forEach((trigger) => {
        if (!trigger.getAttribute('aria-expanded')) {
            trigger.setAttribute('aria-expanded', 'false');
            element.setAttribute('collapsed', '');
        }
        trigger.addEventListener('click', onClick);
    });

    const triggersNotCollapse = Array.from(element.querySelectorAll('.js-not-collapse'));
    triggersNotCollapse.forEach((trigger) => {
        trigger.addEventListener('click', (e) => {
            e.stopImmediatePropagation();
        })
    });
}

export function destroyCollapse(element: Element, triggerSelector = TRIGGER_SELECTOR) {
    const triggers = Array.from(element.querySelectorAll(triggerSelector));
    triggers.forEach((trigger) => {
        trigger.removeEventListener('click', onClick);
    });
}

function init(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll(CONTAINER_SELECTOR));
    elements.forEach((el) => initCollapse(el));
}

function destroy() {
    const elements = Array.from(document.querySelectorAll(CONTAINER_SELECTOR));
    elements.forEach((el) => destroyCollapse(el));
}

const module = { init, destroy, setCollapse, closeCollapse };

export default module;
