import delegate from 'delegate';
import axios from 'axios';
import collapse from '../custom-elements/Collapse/Collapse';
import serialize from 'form-serialize';
import scrollToElement from './scroll-to-element';

let delegation: any;

function hiddenParents() {
    const groups = document.querySelectorAll('.js-fly-ash-group-container.js-collapse');
    groups.forEach(container => {
        const groupObjects = container.querySelectorAll('.js-fly-ash-content-block:not(.is-hidden)');

        if (groupObjects.length === 0) {
            container.classList.add('is-hidden');
        } else {
            container.classList.remove('is-hidden');
        }
    })
}

async function getRequest(
    container: HTMLElement | null,
    url: string, dataForm: any = '',
    target: HTMLElement,
    isFilters:boolean)
{
    target?.classList.add('is-loading');

    await axios.get(url, {
        params: dataForm,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
    })
        .then(response => response.data)
        .then(data => {
            if (container && data.data.listHtml) {
                const objects = container?.querySelectorAll<HTMLElement>('.js-fly-ash-object');

                objects.forEach(object => {
                    const idObject = object.dataset.sectionId;
                    const objectContainer = object.closest('.js-fly-ash-content-block');
                    const contentInner = objectContainer?.querySelector('.js-fly-ash-content');

                    if (idObject && objectContainer && data.data.listHtml[idObject]) {

                        if (contentInner && !isFilters) {
                            contentInner.innerHTML = data.data.listHtml[idObject];
                            collapse.setCollapse(object, true);
                        } else if (contentInner && isFilters) {
                            contentInner.innerHTML = data.data.listHtml[idObject];
                        }

                        objectContainer.classList.remove('is-hidden');
                    } else if (idObject && objectContainer) {
                        objectContainer.classList.add('is-hidden');
                    }
                })
            }

            if (target.classList.contains('js-fly-ash-request-group')) {
                target.setAttribute('aria-expanded', 'true');

                const allGroupTriggers = container?.querySelectorAll('.js-fly-ash-request-group');
                allGroupTriggers?.forEach(groupTrigger => {
                    groupTrigger.setAttribute('aria-expanded', 'true');
                })
            }

        })
        .catch((err) => {
            if (err.name !== 'AbortError') {
                throw err;
            }
        })
        .finally(() => {
            hiddenParents();
            scrollToElement.init();
            setTimeout(() => {
                target?.classList.remove('is-loading');
            }, 300);
        });
}

async function fetchData(this: HTMLInputElement, event: any) {
    const target = event.delegateTarget as HTMLAnchorElement;
    const activeCollapse = target.getAttribute('aria-expanded');
    if (activeCollapse === 'true') {
        collapse.setCollapse(target, true);

        if (target.classList.contains('js-fly-ash-request-group')) {
            const containerGroup = target.closest('.js-fly-ash-content-block');
            const allGroupTriggers = containerGroup?.querySelectorAll('.js-fly-ash-request-group');
            allGroupTriggers?.forEach(groupTrigger => {
                groupTrigger.setAttribute('aria-expanded', 'false');
            })

            const groupCollpses = containerGroup?.querySelectorAll(
                '.js-fly-ash-request:not(.js-fly-ash-request-group)'
            );
            groupCollpses?.forEach(collapseTrigger => {
                collapseTrigger.setAttribute('aria-expanded', 'false');
                collapse.closeCollapse(collapseTrigger);
            })

            const contentParents = containerGroup?.querySelectorAll<HTMLElement>('.js-fly-ash-group-content');
            setTimeout(() => {
                contentParents?.forEach(contentParent => {
                    const contentParentInner = contentParent?.querySelector<HTMLElement>('.js-collapse-parent-inner');

                    if (contentParent) contentParent.style.height = `${contentParentInner?.scrollHeight}px`;
                })
            }, 500);
        }

        return;
    }

    const filtersContainer = target.closest<HTMLElement>('.js-content-block');
    const formFilters: HTMLFormElement | null = filtersContainer ? filtersContainer.querySelector('.js-filters-form') : null;
    const content = target.closest<HTMLElement>('.js-fly-ash-content-block');
    const { sectionId, urlRequest } = target.dataset;

    let dataForm;

    if (formFilters && sectionId) {
        dataForm = serialize(formFilters, { hash: true });
        dataForm.section_id = sectionId;
    } else if (!formFilters && sectionId) {
        dataForm = {
            'section_id': sectionId
        }
    }

    if (urlRequest && content) {
        getRequest(content, urlRequest, dataForm, target, false);
    }
}

async function fetchDataFilters(this: HTMLInputElement, event: any) {
    const target = event.delegateTarget as HTMLAnchorElement;
    const content = target.closest<HTMLElement>('.js-content-block');
    const container = content ? content.querySelector<HTMLElement>('.js-content') : null;
    const formFilters: HTMLFormElement | null = target.closest('.js-filters-form');
    const url = formFilters?.action;

    let dataForm;

    if (formFilters) dataForm = serialize(formFilters, { hash: true });

    if (url && container && content) {
        getRequest(container, url, dataForm, container, true);
    }
}

async function setParentStatus(this: HTMLInputElement, event: any) {
    const target = event.delegateTarget as HTMLAnchorElement;
    const content = target.closest<HTMLElement>('.js-fly-ash-group-container');
    const mainTarget = content?.querySelector<HTMLElement>('.js-fly-ash-request-group');
    const collapses = content?.querySelectorAll<HTMLElement>('.js-fly-ash-object');

    let countActiveCollapses = 0;
    collapses?.forEach(item => {
        if (item.getAttribute('aria-expanded') === 'true') {
            countActiveCollapses++;
        }
    })

    if (countActiveCollapses === 0) mainTarget?.setAttribute('aria-expanded', 'false');
}

function init(container: Element | Document = document) {
    delegation = delegate(container, '.js-fly-ash-request', 'click', fetchData);
    delegation = delegate(container, '.js-fly-ash-object', 'click', setParentStatus);
    delegation = delegate(container, '.js-fly-ash-filter-trigger', 'change', fetchDataFilters);
}

function destroy(container: Element | Document = document) {
    if (delegation) {
        delegation.destroy();
        delegation = null;
    }
}

const _module = { init, destroy };

export default _module;
