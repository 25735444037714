import { viewport } from '../viewport';

let scrollTop;
let scrollBottom;
let headerHeight;
let heightDocument;
let isPpageDown;
const header = document.querySelector('.js-header');
let navAside;
let navItems = [];
let contents = [];
let scrollingTimer;
let resizingTimer;

function isInView(el) {
    if (el) {
        scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        scrollBottom = scrollTop + viewport.height;
        heightDocument = document.body.scrollHeight, document.documentElement.scrollHeight;
        const elRect = el.getBoundingClientRect();
        const elTop = elRect.top + scrollTop;
        const elBottom = elTop + el.offsetHeight;
        isPpageDown = scrollBottom == heightDocument;
        return elTop < scrollBottom && elBottom > scrollTop + headerHeight + 30;
    }
    return false;
}

function markNav(elems) {
    let isAlreadyMarked = false;
    if (navItems.length > 0) {
        for (let i = 0, max = navItems.length; i < max; i++) {
            if (elems.viewStatusList[i] && !isAlreadyMarked) {
                isAlreadyMarked = true;
                navItems.forEach((item, index) => {
                    if (index !== i) {
                        item.classList.remove('is-in-view');
                    }
                });
                if (!isPpageDown) {
                    navItems[i].classList.add('is-in-view');
                }
            }
        }
        if (isPpageDown && elems.viewStatusList[navItems.length - 1]) {
            for (let i = 0, max = navItems.length; i < max; i++) {
                navItems[i].classList.remove('is-in-view');
            }
            navItems[navItems.length - 1].classList.add('is-in-view');
        }
    }
}

function getContents() {
    const targetList = [];

    if (navItems.length > 0) {
        for (let i = 0, max = navItems.length; i < max; i++) {
            const { href } = navItems[i];
            targetList.push(document.getElementById(href.split('#')[1]));
        }
    }

    return targetList;
}

function getElemsViewState() {
    const elemsInView = [];
    const elemsOutView = [];
    const viewStatusList = [];

    if (navItems !== undefined && navItems.length > 0) {
        for (let i = 0, max = contents.length; i < max; i++) {
            const currentContent = contents[i];
            const isInViewState = isInView(currentContent);

            if (isInViewState) {
                elemsInView.push(currentContent);
            } else {
                elemsOutView.push(currentContent);
            }
            viewStatusList.push(isInViewState);
        }
    }

    return {
        inView: elemsInView,
        outView: elemsOutView,
        viewStatusList,
    };
}

function spy() {
    const elems = getElemsViewState();

    markNav(elems);
}

function onResize() {
    if (resizingTimer) {
        clearTimeout(resizingTimer);
    }

    resizingTimer = setTimeout(() => {
        spy();
    }, 10);
}

function onScroll() {
    if (scrollingTimer) {
        clearTimeout(scrollingTimer);
    }

    scrollingTimer = setTimeout(() => {
        spy();
    }, 10);
}

function attachHandlers() {
    spy();
    window.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onResize);
}

function deattachHandlers() {
    window.removeEventListener('scroll', onScroll);
    window.removeEventListener('resize', onResize);
}

function init(container = document) {
    navAside = container.querySelector('.js-aside-nav');
    if (navAside) {
        navItems = navAside.querySelectorAll('a.link');
    }
    headerHeight = header.offsetHeight;

    contents = getContents();
    attachHandlers();
}

function destroy() {
    deattachHandlers();
    scrollTop = null;
    scrollBottom = null;
    headerHeight = null;
    navAside = null;
    navItems = [];
    contents = null;
    scrollingTimer = null;
    resizingTimer = null;
}

const _module = { init, destroy };

export default _module;
