import scrollToElement from './scroll-to-element';
import navThemeObserver from './nav-theme-observer';

let mobileWrapper;
let desctopWrapper;

let isDesctopPosition = true;
let isDesctop;

let asideNav;
let asideNavCopy;
let nextContainer;

function checkPosition() {
    isDesctop = window.matchMedia('(min-width: 1024px)').matches;
}

function reinitHandlers() {
    scrollToElement.destroy(nextContainer);
    scrollToElement.init(nextContainer);
    navThemeObserver.destroy(nextContainer);
    navThemeObserver.init(nextContainer);
}

function replaceNav() {
    if (isDesctop) {
        if (!isDesctopPosition) {
            if (asideNav) {
                asideNav.remove();
            } else {
                if (document.querySelector('.js-aside-nav')) document.querySelector('.js-aside-nav').remove();
            }
            if (desctopWrapper && asideNavCopy) desctopWrapper.appendChild(asideNavCopy);
            if (asideNavCopy) asideNavCopy.classList.add('is-revealed');
            isDesctopPosition = true;
            reinitHandlers();
        }
    } else if (isDesctopPosition) {
        if (asideNav) {
            asideNav.remove();
        } else {
            if (document.querySelector('.js-aside-nav')) document.querySelector('.js-aside-nav').remove();
        }
        if (asideNavCopy) {
            mobileWrapper.appendChild(asideNavCopy);
            asideNavCopy.classList.add('is-revealed');
            isDesctopPosition = false;
            reinitHandlers();
        }
    }
}


function onResize() {
    checkPosition();
    replaceNav();
}

function init(container = document) {
    nextContainer = container;
    mobileWrapper = container.querySelector('.js-editor-aside-nav-mobile-wrapper');
    desctopWrapper = container.querySelector('.js-editor-aside-nav-desctop-wrapper');
    asideNav = container.querySelector('.js-aside-nav');
    if (!mobileWrapper || !desctopWrapper) return;
    asideNavCopy = asideNav?.cloneNode(true);
    checkPosition();
    replaceNav();
    window.addEventListener('resize', onResize);
}

function destroy(container = document) {
    window.removeEventListener('resize', onResize);
    mobileWrapper = null;
    desctopWrapper = null;
    isDesctop = null;
    asideNav = null;
    asideNavCopy = null;
}

const _module = { init, destroy };

export default _module;
