import select from './select';
import axios from 'axios';

function init(container: HTMLElement | Document = document) {
    const firstSelect = container.querySelector<HTMLInputElement>('.js-first-select');
    const secondSelect = container.querySelector<HTMLInputElement>('.js-second-select');
    if (firstSelect && secondSelect) {
        firstSelect.addEventListener('change', () => {
            if (firstSelect.value?.length > 0) {
                secondSelect.disabled = false;
            }
        });
        // const { action } = firstSelect.dataset;
        // const messageElement = firstSelect.closest('div')?.querySelector('.app-message');
        // if (action) {
        //     firstSelect.addEventListener('change', () => {
        //         if (messageElement) {
        //             messageElement.innerHTML = '';
        //         }
        //         axios.get(action).then((response) => {
        //             if (response.data.success) {
        //                 if (response.data.data.options) {
        //                     const secondeSelectInstance = select.getInstanceByElement(secondSelect);

        //                     secondeSelectInstance.setData([
        //                         { text: '', placeholder: true },
        //                         ...response.data.data.options,
        //                     ]);
        //                     secondeSelectInstance.enable();
        //                 }
        //             } else if (messageElement) {
        //                 if (response.data.message) {
        //                     messageElement.innerHTML = response.data.message;
        //                 } else {
        //                     messageElement.innerHTML = 'При загрузке цэхов произошла ошибка.';
        //                 }
        //             } else if (response.data.message) {
        //                 console.error(response.data.message);
        //             } else {
        //                 console.error('При загрузке цэхов произошла ошибка.');
        //             }
        //         });
        //     });
        // }
    }
}

function destroy(container: HTMLElement | Document = document) {}

const _module = { init, destroy };

export default _module;
