import delegate from 'delegate';

let delegation: any;

async function fetchData(this: HTMLInputElement, event: any) {
    event.preventDefault();
    const target = event.delegateTarget as HTMLSelectElement;
    const content = target.closest<HTMLElement>('.js-simple-container');
    const items = content ? content.querySelectorAll<HTMLElement>('.js-simple-filter-content') : null;

    const activeValue = target.value;

    items?.forEach(item => {

        const valueItem = item.dataset.filterContent;

        if (activeValue == 'all') {
            item.classList.remove('is-hide');
        } else if (activeValue == valueItem) {
            item.classList.remove('is-hide');
        } else {
            item.classList.add('is-hide');
        }
    })
}

function init(container: Element | Document = document) {
    delegation = delegate(container, '.js-simple-filter-trigger', 'change', fetchData);
}

function destroy(container: Element | Document = document) {
    if (delegation) {
        delegation.destroy();
        delegation = null;
    }
}

const _module = { init, destroy };

export default _module;
