import Swiper, { Pagination, Autoplay } from 'swiper';

Swiper.use([Pagination, Autoplay]);

const map = new WeakMap<HTMLElement, any>();

function init(container: HTMLElement | Document = document) {
    const gallerySliders = Array.from(container.querySelectorAll<HTMLElement>('.js-about-text-slider'));

    gallerySliders.forEach(slider => {
        const realSlides = slider.querySelectorAll<HTMLElement>('.swiper-slide');
        const pagination = slider.querySelector<HTMLElement>('.js-about-text-slider-pagination');

        const swiper = new Swiper(slider, {
            slidesPerView: 1,
            loop: true,
            speed: 600,
            watchOverflow: true,
            autoplay: {
                delay: 6000,
            },
            pagination: {
                el: pagination,
                bulletClass: 'text-slider__bullet',
                type: 'bullets',
                bulletActiveClass: 'is-active',
                clickable: true
            },
            breakpoints: {
                768: {
                    autoHeight: true,
                }
            }
        });

        if (realSlides.length > 1) {
            swiper.init();
            if (pagination) pagination.style.display = 'flex';
        } else {
            swiper.disable();
            if (pagination) pagination.style.display = 'none';
        }
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-about-text-slider')).forEach((el) => {
        const slider = map.get(el);

        if (slider) {
            slider.destroy();
            map.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;
